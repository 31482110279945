import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton
  // Customizable Area Start
  , Grid, TextField, Tab, Tabs, Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import HeaderWeb from "../../../components/src/Header.web";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Dropzone from 'react-dropzone';
import { Formik } from 'formik';
import { Footer } from "../../../components/src/Footer.web";

import * as Yup from 'yup'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
});

// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const files = this.state.files.map((file: any) => (
      <Grid container style={{ justifyContent: "center", display: "flex" }} key={file.name}>
        {file.name}
      </Grid>
    ));

    const urlValidation = Yup.object().shape({
      file_link:
        Yup.string().required('please valid url'),
    });
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderWeb />
        <ThemeProvider theme={theme}>
          <Typography variant="h5" style={{ paddingLeft: "90px", fontSize: '30px', marginTop: "30px", fontFamily: 'Roboto', }}>Import and Export</Typography>
          <Grid container style={{ paddingLeft: "90px", marginTop: "5px", fontFamily: 'Roboto', }}>
            Here you can export and import the files
          </Grid>
          <DividerLine variant="middle" />
          <Formik
            initialValues={this.state.importData}
            validationSchema={urlValidation}
            onSubmit={() => { }}
          >
            {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: any) => (
              <form onSubmit={handleSubmit}>
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item style={{ alignItems: "center" }}>
                    <CustomTabs
                      style={{ justifyContent: "center", display: "flex" }}
                      onChange={this.handleChangeTab}
                      value={this.state.selectedTab}
                      aria-label="Tabs where each tab needs to be selected manually"
                    >
                      <Grid container style={webStyle.Tab2}
                      >
                        <CustomTab
                          onClick={(e: any) => this.handleChangeTab(e, 0)}
                          value={0}
                          label={
                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                              <p style={{ margin: '0px', display: 'flex', fontSize: "13px", fontFamily: 'Roboto' }}>
                                Import
                              </p>
                            </Box>
                          }
                          style={this.state.selectedTab === 0 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                          disableRipple
                        />
                      </Grid>
                      <Grid container
                        style={webStyle.activeTransferredTickets}
                      >
                        <CustomTab
                          value={1}
                          onClick={(e: any) => this.handleChangeTab(e, 1)}
                          label={
                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                              <p style={{ margin: '0px', display: 'flex', fontSize: "13px", fontFamily: 'Roboto', }}>
                                Export
                              </p>
                            </Box>
                          }
                          style={this.state.selectedTab === 1 ? { ...webStyle.Tab1, position: 'relative' } : webStyle.tab3}
                          disableRipple
                        />
                      </Grid>
                    </CustomTabs>
                  </Grid>
                </Grid>
                <Grid container
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Grid item
                    style={{
                      textAlign: "center",
                      background: "#F2F2F2",
                      padding: "0px 75px",
                      borderRadius: "12px",
                      width: "30%",
                      marginBottom:100
                    }}
                  >
                    {this.state.selectedTab === 0 ?
                      (<div>
                        <Dropzone
                          onDrop={this.onDrop}
                          accept={{
                            'text/csv': [
                              '.csv'
                            ],
                          }}
                        >
                          {({ getRootProps, getInputProps }: any) => (
                            <section style={{ marginTop: "40px", justifyContent: "center" }}>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p style={{ fontSize: "15px", fontFamily: 'Roboto', }}> Select the file to import your data</p>
                                <aside>
                                  <Grid container
                                    style={{
                                      border: "2px dashed darkgrey",
                                      borderRadius: "13px",
                                      justifyContent: "center",
                                      marginTop: "27px",
                                      padding: "35px 35px 35px",
                                    }}>
                                    <Grid item style={{ textAlign: "center" }}>
                                      <OpenInNewIcon style={{ color: "darkorchid" }} />
                                      <div
                                        style={{ marginTop: "12px", color: "rgba(132, 132, 132, 1)", fontFamily: 'Roboto', fontSize: "13px" }}>
                                        Drag and drop a file in csv format
                                        <div style={{ marginTop: "5px" }}>{files}</div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </aside>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <Grid container
                          style={{
                            justifyContent: "center",
                            marginTop: "10px",
                            color: "rgba(132, 132, 132, 1)",
                            fontSize: "14px",
                            fontFamily: 'Roboto',
                          }}
                        >
                          Or
                        </Grid>
                        <Grid container>
                          <InputField
                            name='file_link'
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            placeholder="Paste the csv file link here"
                            error={errors?.file_link && touched?.file_link}
                          />
                          {errors?.file_link && touched?.file_link &&
                            <div style={webStyle.fieldError}>
                              {errors?.file_link}
                            </div>
                          }
                        </Grid>
                        {console.log(errors?.file_link, touched?.file_link, "7777")}
                        <Grid container style={{ justifyContent: "center", padding: "30px" }} >
                          <Button variant="contained" color="primary"
                            style={{
                              background: "black",
                              borderRadius: "33px",
                              textTransform: "capitalize",
                              width: "8em",
                              fontSize: "13px",
                              fontFamily: 'Roboto',
                            }} 
                            /* istanbul ignore next */
                            onClick={this.handleClick}
                            type='submit'
                          >
                            Import
                          </Button>
                        </Grid>
                      </div>
                      ) : (
                        <Grid container style={{ justifyContent: "center", padding: "30px" }} >
                          <Button variant="contained" color="primary"
                            style={{
                              background: "black",
                              borderRadius: "33px",
                              textTransform: "capitalize",
                              width: "8em",
                              fontSize: "13px",
                              fontFamily: 'Roboto',
                            }}
                            type='submit'
                          >
                            Export
                          </Button>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>

              </form>
            )}
          </Formik>
        </ThemeProvider>
        <Footer />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: 'Roboto',
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0px"
  },
  buttonStyle: {
    marginTop: "30px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  Tab1: {
    background: 'black',
    color: 'white'
  },
  tab3: {
    borderTopLeftRadius: "33px",
    borderBottomLeftRadius: "33px",
  },
  Tab2: {
    borderTopLeftRadius: "33px",
    borderBottomLeftRadius: "33px",
    background: 'lightgray',
    color: "white",
    width: "44%"
  },
  activeTransferredTickets: {
    borderTopRightRadius: "33px",
    borderBottomRightRadius: "33px",
    background: 'lightgray',
    color: "white",
    width: "48%"
  },
};
const DividerLine: any = withStyles({
  root: {
    "&.MuiDivider-middle": {
      marginLeft: "85px",
      marginRight: "85px",
      marginTop: "12px",
      height: "2px",
    }
  }
})(Divider);
const InputField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiFilledInput-root": {
      fontWeight: "Regular",
      marginTop: "13px",
      fontFamily: 'Roboto',
      background: "white",
      color: "rgba(132, 132, 132, 1)",
      fontSize: "13px",
      borderRadius: "13px",
      border: "none",
      "& .MuiFilledInput-input": {
        padding: "20px"
      },
      "& fieldset": {
        borderRadius: "13px",
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
    }
  }
})(TextField);


const CustomTabs: any = withStyles({
  root: {
    margin: "30px",
    "& .MuiTabs-flexContainer": {
      borderRadius: "33px",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      background: "#0000",
      color: '#ffff',
      maxWidth: '280px',
      "& .MuiTab-textColorInherit": {
        opacity: 0.7,
        background: "#0000",
        color: '#ffff',
      },
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
  },
})(Tabs);

const CustomTab: any = withStyles({
  root: {
    fontSize: "14px",
    textTransform: "none",
    borderRadius: '33px',
  },
})(Tab);
// Customizable Area End