import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo } from "./assets";
import './ForgotPassword.css'
import { Formik  } from 'formik';
import * as Yup from 'yup';

import ForgotPasswordController,{Props} from "./ForgotPasswordController";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });


  export default class ForgotPassword extends ForgotPasswordController {
    constructor(props:Props){
        super(props)
    }

    render(){
        return(
        <Box style={{
          margin:'0 rem',
          padding:'0 rem',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          height:'100vh'
        }}>
          <Box sx={{
            width:'24rem',
            bgcolor:'black',
            color:'white',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            padding:'3rem 1rem',
            borderRadius:'0.5rem',
          }}>
            <Box style={{width:'100%',}}>
              <img style={{width:'100%', height:'100px',objectFit:'cover'}} src={logo}/>
          </Box>

            <Typography 
              className='fontStyle'
              style={{
                  margin:'0.5rem',
                  fontSize:'1rem',
                  fontWeight:'normal',
              }} 
              variant="h6" 
              component="h6"
                >
              Reset Password
            </Typography>


            <Formik
              validateOnChange
              validateOnBlur
              initialValues={{
                fullName: '',
                email: '',
                password: '',
                reTypePassword: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Email is required').matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,'Invalid Email'),
              })}
        
              onSubmit={(values: any) => 
                {
                  this.sendOTP(values)
                }
              }
            >

{({ handleSubmit, handleChange ,values, touched, errors }:any) => (
          
          <form onSubmit={handleSubmit}>

            <Box sx={{ 
                padding: "0.5rem 1rem",
                display:'flex',
                flexDirection:'column',
                margin:'0.5rem',
                width:'22rem',
                }}> 
                <Typography className="fontStyle">Your Email</Typography>
                <Input
                  name="email"
                  error={errors.email && touched.email}
                  value={values?.email}
                  onChange={(e)=> {
                    localStorage.setItem("email",e.target.value);
                    handleChange(e)
                  }}
                  disableUnderline
                  onFocus={()=>this.setState({emailColor:'yellow'})}
                  onBlur={()=>this.setState({emailColor:'black'})
                              }
                  style={{
                    backgroundColor:'white',
                    color:'black',
                    padding:'0.5rem',
                    borderRadius:'0.5rem',
                    border:`${this.state.emailColor} 2px solid` 
                  }}
                />
                 {errors.email && touched.email&&<p className="fontStyle errorMessage">{errors.email}</p>}
              </Box>
              <Box style={{width:'100%', display:'flex',justifyContent:'center'}}>
              <Button 
                className="fontStyle"
                type="submit"
                style={{
                  color:'white',
                  backgroundColor: 'rgba(199, 0, 57, 1)',
                  padding:'0.5rem',
                  margin:'1rem',
                  width:'10rem',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  borderRadius:'0.5rem',
                  textTransform:'none',
                  fontWeight:'normal'
                  }}
                  >
                  Submit
              </Button></Box>
                </form>
                )}
              </Formik>
            
          </Box>
        </Box>
        )
    }

  }

// Customizable Area End
