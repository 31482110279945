Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/candidates/total_candidates";
exports.getCandidateList = "account_block/candidates/candidates";
exports.addDateTimeApiUrl = "account_block/candidates/candidates/";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.baseUrl = "";
exports.searchCandidateUrl = 'bx_block_dashboard/candidates/advanced_search'
exports.AdHocCountGetApiEndPoint = 'bx_block_posts/post/ad_hoc_counts'
exports.AdHocGraphGetApiEndPoint = 'bx_block_posts/post/select_reports'
exports.AdHocProgressGetAPIEndPoint = 'bx_block_posts/post/reports'
exports.searchCandidateUrl = 'bx_block_dashboard/candidates/advanced_search';
exports.tableColumns = [
  {
    sortable: false,
    field: "candidate_name",
    headerName: "Candidate Name",
    editable: false,
    width: 200,
  },
  {
    editable: false,
    field: "job_type",
    sortable: false,
    width: 200,
    headerName: "Job Type",
  },
  {
    headerName: "Company",
    field: "company_name",
    editable: false,
    width: 200,
    sortable: false,
  },
  {
    field: "city",
    editable: false,
    width: 200,
    headerName: "Location",
    sortable: false,
  },
  {
    sortable: false,
    width: 200,
    field: "submission_date",
    headerName: "Submitted Date",
    editable: false,
  },
  {
    headerName: "Interview Date",
    sortable: false,
    field: "interview_date",
    editable: false,
    width: 200,
  },
  {
    headerName: "Status",
    sortable: false,
    editable: false,
    field: "status",
    width: 200,
  }
]
// Customizable Area End