import React from 'react';
import { Checkbox, Avatar } from "@material-ui/core";
import { Pagination, PaginationItem } from '@material-ui/lab';
import AdvancedSearchControllerWeb, { Props } from '../../../blocks/advancedsearch/src/AdvancedSearchController.web';
import { CompanyIcon, locationIcon, positionIcon } from '../../../blocks/advancedsearch/src/assets';


class ActiveCandidateList extends AdvancedSearchControllerWeb {

  constructor(props: Props) {
    super(props);
  }

  handleChangePage = (_event: any, newPage: number) => {
    this.setState({ tableOnePage: newPage });
  };

  handleRowSelection = (_event: any, row: any) => {
    const { tblOneSelectedRow } = this.state;
    const { id } = row;
    const isSelected = tblOneSelectedRow.includes(id);
    let newSelectedRows: any[] = [];
  
    if (isSelected) {
      newSelectedRows = tblOneSelectedRow.filter((selectedId:any) => selectedId !== id);
    } else {
      newSelectedRows = [...tblOneSelectedRow, id];
    }
  
    this.setState({ tblOneSelectedRow: newSelectedRows });
    this.props.selectedData(newSelectedRows);
  };

  calculatePagination = () => {
    const { tableOnePage } = this.state;
    const { dataArray } = this.props;
    const startIndex = (tableOnePage - 1) * this.props.recordsPerPage;
    const endIndex = startIndex + this.props.recordsPerPage;
    const currentPageCandidates = dataArray?.slice(startIndex, endIndex);

    const candidatesRangeStart = startIndex + 1;
    const candidatesRangeEnd = Math.min(startIndex + this.props.recordsPerPage, dataArray.length);
    const paginationCount = Math.ceil(dataArray.length / this.props.recordsPerPage);
    const visiblePages = 5;
    const siblingCount = Math.floor((visiblePages - 1) / 2);
    const boundaryCount = siblingCount + 1;
    let startPage = Math.max(tableOnePage - siblingCount, 1);
    let endPage = Math.min(startPage + visiblePages - 1, paginationCount);

    console.log("@===> Check data", currentPageCandidates);
    
    if (endPage - startPage < visiblePages - 1) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    return {
      currentPageCandidates,
      candidatesRangeStart,
      candidatesRangeEnd,
      siblingCount,
      boundaryCount,
      paginationCount
    };
  }

  render() {
    const { tblOneSelectedRow, tableOnePage } = this.state;
    const {
      currentPageCandidates,
      candidatesRangeStart,
      candidatesRangeEnd,
      siblingCount,
      boundaryCount,
      paginationCount
    } = this.calculatePagination();

    const is_tblRowContainerEmpty = currentPageCandidates.length > 0 ? "" : "empty";

    return (
      <>
        <div className='candidate-tbl'>
          <div
            className={`tbl-row-container ${is_tblRowContainerEmpty}`}
            style={{ minHeight: this.props.recordsPerPage > 14 ? "530px" : "" }}
          >
            {currentPageCandidates.length > 0 ?
              currentPageCandidates.map((item: any) => (
                <div key={item?.id} className='tbl-row'>

                  <div className='tbl-cell avatar'>
                    <Avatar alt={item?.attributes?.candidate_name} src={""} />
                  </div>

                  <div className="tbl-cell multiple">
                    <span>{item?.attributes?.candidate_name}</span>
                    <span>
                      <img src={positionIcon} width={15} />
                      {item.attributes?.position ? item.attributes.position : "-"}
                    </span>
                    <span>
                      <img src={locationIcon} width={15} />
                      {item.attributes.city || item.attributes.state ? (item.attributes.city + ", " + item.attributes.state) : "-"}
                    </span>
                    <span>
                      <img src={CompanyIcon} width={15} />
                      {item.attributes.company_name ? item.attributes.company_name : "-"}
                    </span>
                  </div>

                  <div className="tbl-cell selection">
                    <Checkbox
                      checked={tblOneSelectedRow.includes(item.id)}
                      onChange={(event: any) => this.handleRowSelection(event, item)}
                    />
                  </div>

                </div>
              )) :
              (<div className='listEmpty-msg'>
                No candidates record found.
              </div>)
            }
          </div>
        </div>
        <div className='tblOne-footer'>
          <span className='status'>{`${candidatesRangeStart} to ${candidatesRangeEnd} of ${this.props.dataArray.length} candidates`}</span>
          <Pagination
            count={paginationCount}
            page={tableOnePage}
            onChange={this.handleChangePage}
            color="primary"
            boundaryCount={boundaryCount}
            siblingCount={siblingCount}
            renderItem={(item) => (
              <PaginationItem
                component="div"
                {...item}
                onClick={(e:any) => this.handleChangePage(e, item.page)}
              />
            )}
          />
        </div>
      </>
    );
  }
}

export default ActiveCandidateList;


