import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { apiCall, checkSession, isLoggedIn } from "../../../components/src/Common";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: any;
  name: any;
  password: any;
  phoneNumber: any;
  showPasswordInput: boolean;
  initialCreds: any;
  data: any[];
  userDetails: any;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableNewPasswordField: boolean;
  theme: any;
  editorHtml: any;
  currentPasswordText: any;
  newPasswordText: any;
  openNotification: any;
  errorOpenNotification: any;
  isError: any
  selectedProfileFile: any,
  selectedProfileImage: any;
  pageLoading: any;
  messageText: any;
  isDetails: any;
  profilePasswordValue: any;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiProfileCreateCallId: string = "";
  apiProfileUpdateCallId: any;
  apiPostCreationCallId: string = "";
  passwordReg: RegExp;
  emailReg: RegExp;
  authToken: any;
  userProfileGetApiCallId: any;
  getUserProfileData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      email: "",
      password: "",
      name: "",
      selectedProfileFile: null,
      selectedProfileImage: null,
      isDetails: "",
      phoneNumber: "",
      pageLoading: true,
      showPasswordInput: false,
      openNotification: false,
      errorOpenNotification: false,
      profilePasswordValue: "",
      isError: false,
      initialCreds: {
        current_password: "",
        new_password: "",
        // email: '',
      },
      userDetails: {

      },
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableNewPasswordField: true,
      theme: true,
      editorHtml: "",
      currentPasswordText: "",
      newPasswordText: "",
      messageText: ''

    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    /* istanbul ignore next */
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      this.checkPasswordResponse({ apiRequestCallId, responseJson })
      this.checkuserProfileResponse({ apiRequestCallId, responseJson })
    }

    // Customizable Area End
  }



  // Customizable Area Start
  handleProfilePhotoUpload = (event: any) => {
    /* istanbul ignore next */
    this.setState({
      selectedProfileFile: event.target.files[0],
      selectedProfileImage: URL.createObjectURL(event.target.files[0])
    });
  };

  finalErrorMessage(error: any, touch: any) {
    /* istanbul ignore next */
    if (error && touch) {
      /* istanbul ignore next */
      return <div style={{
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0px"
      }}>
        {error}
      </div>

    }
  }

  handleChangePassword = () => {
    this.setState({ showPasswordInput: !this.state.showPasswordInput })
  }

  handleSubmit = async (values: any) => {
    this.setState({ profilePasswordValue: values });

    if (this.state.showPasswordInput) {
      await this.profilePasswordUpdate(this.state.profilePasswordValue);
      setTimeout(() => { this.profileUpdate(values) }, 1500);
    } else {
      await this.profileUpdate(values);
    }
  }

  async componentDidMount() {
    this.getProfileData();
    isLoggedIn(this?.props?.navigation)
  }

  commonErrorSection({ responseJson }: any) {
    /* istanbul ignore next */
    if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  checkuserProfileResponse({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId && responseJson) {
      /* istanbul ignore next */
      if (apiRequestCallId === this.getUserProfileData) {
        checkSession(responseJson, this.props.navigation)
        /* istanbul ignore next */
        if (
          !responseJson.errors &&
          responseJson.data &&
          responseJson.data.attributes
        ) {
          //On User Profile Success
          const sortedData = {
            ...responseJson.data, "attributes": {
              ...responseJson.data.attributes, current_password: '', new_password: ''
            }
          }
          this.setState({ userDetails: sortedData }, this.handleStopLoading)
        } else {
          //Check Error Response
          this.commonErrorSection({ errorReponse })
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.checkProfileUpdate({ apiRequestCallId, responseJson, errorReponse })
    }
  }

  async checkProfileUpdate({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId === this.apiProfileUpdateCallId) {
      checkSession(responseJson, this.props.navigation)
      /* istanbul ignore next */
      if (
        !responseJson.errors &&
        responseJson.data &&
        responseJson.data.attributes
      ) {
        //On User Profile Success
        const proImg: any = document.querySelector('.profileImage')
        proImg.src = responseJson?.data?.attributes?.photo
        this.setState({ isError: false, openNotification: true, messageText: 'Your profile successfully updated' });
        setTimeout(() => {
          this.setState({ openNotification: false })
        }, 1000);

        const sortedData = {
          ...responseJson.data, "attributes": {
            ...responseJson.data.attributes, current_password: '', new_password: ''
          }
        }
        this.setState({ userDetails: sortedData }, this.handleStopLoading)
      } else {
        this.commonErrorSection({ errorReponse });
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  checkPasswordResponse({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId && responseJson) {
      /* istanbul ignore next */
      if (apiRequestCallId === this.apiProfileCreateCallId) {
        checkSession(responseJson, this.props.navigation)
        /* istanbul ignore next */
        if (
          !responseJson.errors &&
          responseJson.data &&
          responseJson.data.attributes
        ) {
          //On User Profile Success
          const sortedData = {
            ...responseJson.data, "attributes": {
              ...responseJson.data.attributes, current_password: '', new_password: ''
            }
          }
          this.setState({ userDetails: sortedData })
          localStorage.setItem('photo', responseJson.data?.attributes?.photo)
          this.setState({ pageLoading: true })
          setTimeout(() => {
            this.setState({ pageLoading: false, showPasswordInput: false })
          }, 1000);

        } else {
          //Check Error Response
          this.setState({ isError: true, errorOpenNotification: true, messageText: responseJson?.meta?.message || 'Your Last password is wrong' })
          setTimeout(() => {
            this.setState({ errorOpenNotification: false })
          }, 2000);
          this.commonErrorSection({ errorReponse });
        }
      }
    }
  }

  handleStopLoading() {
    this.setState({ pageLoading: false })
  }

  getProfileData() {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: token
    };

    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: `${configJSON.endPointGetUserProfile}`,
      httpMethod: configJSON.methodApiGetUserProfile,
    });
    this.getUserProfileData = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickHidePassword = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField,
    });
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  profilePasswordUpdate = (values: any) => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.profilePasswordUpdate, token: token
    };

    const PostCreationData = {
      'current_password': values.current_password,
      'new_password': values.new_password
    };
    const httpBody = {
      data: PostCreationData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfileCreateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profilePasswordUpdateAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profilePasswordUpdateApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  profileUpdate = async (values: any) => {
    /* istanbul ignore next */
    const token = localStorage.getItem('token')
    const header = { Token: token };

    let formData: any = new FormData();
    formData.append('data[first_name]', values?.first_name)
    formData.append('data[last_name]', values?.last_name)
    formData.append('data[email]', values?.email)
    formData.append('data[phone_number]', values?.phone_number)
    /* istanbul ignore next */
    if (this.state.selectedProfileFile) {
      formData.append('data[photo]', this.state.selectedProfileFile)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProfileUpdateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointPutUserProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End

}
