import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Link
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MailIcon from "@material-ui/icons/Mail";
import MenuRounded from "@material-ui/icons/Menu";
import "../assets/css/MailingListIntegration.web.css";
// Customizable Area End

import MailingListIntegrationController, {
  Props,
  configJSON,
} from "./MailingListIntegrationController";

export default class MailingListIntegration extends MailingListIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="mailingList-page">
        <header className="page-header">
          <Button><MenuRounded color="inherit" /></Button>
          <div className="left">
            <Typography>Mailing List</Typography>
          </div>
        </header>

        <div className="page-wrapper">
          <div className="sidebar">
            <Button className="menu-btn">Lorem ipsum</Button>
            <Button className="menu-btn">Lorem ipsum</Button>
            <Button className="menu-btn active">Mailing List Integration</Button>
            <Button className="menu-btn">Lorem ipsum</Button>
            <Button className="menu-btn">Lorem ipsum</Button>
          </div>

          <Box className="data-container">
            <Link href={`mailto:${this.state.allUsersEmail}`}>
              <Button className="mailAll-btn">
                Send Mail to All
              </Button>
            </Link>
            {!this.state.loading && this.state.userData &&
              <Box className="email-list">
                {this.state.userData.map((user) => (
                  <Box className="userEmail-box" key={user.email}>
                    <Box>{user.email}</Box>
                    <Link style={{color: 'black'}} href={`mailto:${user.email}`}><MailIcon /></Link>
                  </Box>
                ))}
              </Box>
            }
          </Box>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
