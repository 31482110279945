import React from "react";
import PrioritiseController from "./PrioritiseController.web";
import { Button, OutlinedInput, Checkbox } from "@material-ui/core";
import { menuIcon, plusIcon, searchIcon } from "./assets";
import { CheckCircle, RadioButtonUnchecked, CancelOutlined, EditOutlined } from "@material-ui/icons";
import "./Prioritise.web.css";


export class Prioritise extends PrioritiseController {

    constructor(props: any) {
        super(props);
    }

    render() {
        
        const { jobList, isFromExpanded, topicTitle, topicDescription, errorMessage } = this.state;

        return (
            <div className="prioritise-page" id="Main">
                <div className="page-header">
                    <div className="header-left">
                        <Button className="menu-btn">
                            <img src={menuIcon} width={20} />
                        </Button>
                    </div>
                    <div className="header-right">
                        <span className="page-title">Prioritise</span>
                        <Button className="search-btn">
                            <img src={searchIcon} width={20} />
                        </Button>
                    </div>
                </div>
                <div className="page-content">
                    <div className="sidebar">
                        <Button className="menu-btn">Lorem ipsum</Button>
                        <Button className="menu-btn">Lorem ipsum</Button>
                        <Button className="menu-btn active">Prioritise</Button>
                        <Button className="menu-btn">Lorem ipsum</Button>
                        <Button className="menu-btn">Lorem ipsum</Button>
                    </div>
                    <div className="data-wrapper">
                        <form className={`addtopic-form ${isFromExpanded ? "active": ""}`}>
                            <OutlinedInput
                                className="add-input"
                                id="topicTitle"
                                placeholder="Lorem Ipsum"
                                value={topicTitle}
                                onChange={(e) => this.handleInputChange(e)}
                            />
                            {isFromExpanded &&
                                <OutlinedInput
                                    className="add-input"
                                    id="topicDescription"
                                    placeholder="Enter Description"
                                    value={topicDescription}
                                    onChange={(e) => this.handleInputChange(e)}
                                />
                            }
                            <Button
                                type={isFromExpanded ? "submit" : "button"}
                                onClick={(e) => this.handleFormSubmit(e)}
                            >
                                <img src={plusIcon} width={20} />
                            </Button>
                            {errorMessage && <span className="validation-msg">{errorMessage}</span>}
                        </form>
                        <div className="topic-list">
                            {jobList.length > 0 ?
                                jobList?.map((item: any, index: any) =>
                                    <div
                                        key={item.id}
                                        className="topic"
                                        draggable
                                        onDragStart={() => this.dragStart(index)}
                                        onDragEnter={() => this.dragEnter(index)}
                                        onDragEnd={() => this.drop()}
                                    >
                                        <Checkbox
                                            className="checkbox-input"
                                            icon={<RadioButtonUnchecked color="inherit" />}
                                            checkedIcon={<CheckCircle color="inherit" />}
                                        />
                                        <span className="topic-name">{item.title}</span>
                                        <span className="topic-dis">{item.description}</span>
                                        <div className="topic-actions">
                                            <Button className="edit" onClick={() => this.handleTopicUpdate(item.id)}>
                                                <EditOutlined color="inherit" />
                                            </Button>
                                            <Button className="cancle" onClick={() => this.deleteTopic(item.id)}>
                                                <CancelOutlined color="inherit" />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="topic empty-msg">No prioritise found!</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Prioritise;