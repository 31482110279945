import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProviderWeb from "../../../framework/src/StorageProvider.web";
import { ApiCall } from "../../../components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface CandidateInternalAttributes {
  id: string,
  candidate_name: string,
  address: string,
  company_name: string,
  position: string,
  state: string,
  city: string,
  recruiter_name: string,
  created_at: string,
  updated_at: string,
  medium: string
  recruiter: string,
  contacted: string,
  submitted: string,
  intrested: string,
  designation: string,
  submission_date: string,
  interview_date: string,
  category: string,
  clearence_level: string,

}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: number;
  interviWithClient: number,
  candidatesExpectingOffer: number,
  submittedForFeedback: number,
  candidatesAccepted: number,
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  booleanSearch: boolean;
  newValue: string;
  activeCandidateList: Array<CandidateInternalAttributes>;
  jobtype: string;
  clearance_level: string;
  job_platforms: string;
  experience: string;
  select_state: string;
  select_city: string;
  profile: string;
  clearance: any;
  platformArr: any;
  experienceArr: any;
  cityArr: any;
  stateArr: any;
  columns: any;
  cityDisable: boolean
  // Customizable Area End
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getActiveCandidateDetails: string = "";
  getAllCityDetails: string = '';
  getAllStateDetails: string = '';
  getCandiadteCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: 0,
      interviWithClient: 0,
      candidatesExpectingOffer: 0,
      submittedForFeedback: 0,
      candidatesAccepted: 0,
      errorMsg: "",
      token: "",
      loading: false,
      booleanSearch: false,
      newValue: "1",
      activeCandidateList: [],
      jobtype: "",
      clearance_level: '',
      job_platforms: '',
      experience: '',
      select_state: '',
      select_city: '',
      profile: '',
      clearance: [
        {
          value: 'TS/SCI',
        },
        {
          value: 'TS/SCI CI Poly',
        },
        {
          value: 'TS/SCI FSP ',
        },
        {
          value: 'Public Trust',
        },
        {
          value: 'Secret',
        },
        {
          value: 'Top secret',
        },
      ],
      platformArr: [
        {
          value: 'LinkedIn',
        },
        {
          value: 'Indeed',
        },
        {
          value: 'Clearancejob.com',
        }
      ],
      experienceArr: [
        {
          value: '1-2 years',
        },
        {
          value: '3-4 years',
        },
        {
          value: '5-6 years',
        }
      ],
      cityArr: [

      ],
      stateArr: [

      ],
      columns: configJSON.tableColumns,
      cityDisable: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    /* istanbul ignore next */
    if (localStorage.getItem('token')) {
      let token = await this.getStorageProvider()
      this.setUpdateToken(token);
    }
    // Customizable Area End
  }
  setUpdateToken = (token: string) => {
    /* istanbul ignore next */
    this.setState({ token: token }, () => {
      this.getAllActiveCandidateDetails()
      this.getDashboardData()
      this.getAllStates()
    });
  }

  getStorageProvider = async () => {
    return StorageProviderWeb.get("token");
  }
  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleStateChange = (e: any) => {
    /* istanbul ignore next */
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  handleSearchCandidate = async () => {
    const token = localStorage.getItem("token");
    const {
      clearance_level,
      jobtype,
      job_platforms,
      select_city,
      select_state,
      profile,
      experience
    } = this.state;

    const totalEx = experience?.split("-");
    const minExperience = totalEx[0];
    const maxExperience = totalEx[1]?.split(" ")[0];

    const totalActive = profile?.split("-");
    const minActive = totalActive[0];
    const maxActive = totalActive[1]?.split(" ")[0];


    const params = new URLSearchParams();
    params.append("job_platform", job_platforms);
    params.append("min_experience", minExperience);
    params.append("max_experience", maxExperience ? maxExperience : '');
    params.append("state", select_state);
    params.append("city", select_city);
    params.append("min_active", minActive);
    params.append("max_active", maxActive ? maxActive : '');
    params.append("job_type", jobtype);
    params.append("clearence_level", clearance_level);

    this.getCandiadteCallId = await ApiCall({
      contentType: configJSON.dashboarContentType,
      token: token,
      method: "GET",
      endPoint: configJSON.searchCandidateUrl + `?${params}`
    });
  }
  handleChangeTab = (event: any, newValue: string) => {
    this.setState({
      newValue: newValue,
    });
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getActiveCandidateDetails) {
      //On Success response
      /* istanbul ignore next */
      this.setState({
        activeCandidateList: responseJson.candidates.data
      })

    }
    if (apiRequestCallId === this.getCandiadteCallId) {
      //On Success response
      /* istanbul ignore next */
      this.setState({
        activeCandidateList: responseJson.candidates.data ? responseJson.candidates.data : []
      })

    }
    if (apiRequestCallId === this.apiDashboardItemCallId) {
      /* istanbul ignore next */
      this.setState({
        totalCandidates: responseJson.total_candidates,
        interviWithClient: responseJson.interview_with_client,
        candidatesExpectingOffer: responseJson.candidates_expecting_offer,
        submittedForFeedback: responseJson.submitted_for_feedback,
        candidatesAccepted: responseJson.candidates_accepted
      })

    }
    if (apiRequestCallId === this.getAllCityDetails) {
      //On Success response
      /* istanbul ignore next */
      this.setState({
        cityArr: responseJson.cities ? responseJson.cities : []
      })
    }
    if (apiRequestCallId === this.getAllStateDetails) {
      //On Success response
      /* istanbul ignore next */
      this.setState({
        stateArr: responseJson.states ? responseJson.states : []
      })
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getAllActiveCandidateDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getActiveCandidateDetails = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/candidates/advanced_search`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllCities = async (id: number) => {
    this.setState({ cityDisable: false })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCityDetails = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_address/states/${id}/cities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllStates = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllStateDetails = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_address/states`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
