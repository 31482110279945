import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {closeIcon,outlook} from '../../dashboard/src/assets'
import OutlookIntegrationControllerNew from './OutlookIntegrationControllerNew'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import OutlookIntegrationController, {
  Props,
  configJSON,
} from "./OutlookIntegrationController";

export default class OutlookIntegrationNew extends OutlookIntegrationControllerNew {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
     <Button  data-test-id="txtInput"   onClick={this.handleOpen}  style={{ transform: 'translate(-50%, -50%)',position:'fixed' ,color:'white',backgroundColor:'#3385ff',  top:'50%',padding:'10px',justifyContent:'center', alignItems:'center', width:"320px" ,left:'50%', textTransform:'capitalize' }}>Please login with your account</Button>
        <Dialog
          open={this.state.openDilog} onClose={this.handleClose} 
          PaperProps={{ style: { minWidth: "700px", height: "440px", top:'15%' } }}
        ><Box style={styles.main}>
            <Box>
                <Box style={{display:'flex', height:'55px', justifyContent:'space-between',  backgroundColor: "#E0E0E0",  alignItems: "center"}}>
              <Box style={styles.header}> Outlook Integration</Box><img  data-test-id="txtInput1" onClick={this.handleClose} src={closeIcon} width={15} height={17}style={{ justifyContent: "center", padding:"20px", cursor:'pointer'
      ,alignItems: "center", margin:'3px'}} /></Box> 
              <Box style={styles.center}> <img  onClick={this.handleClose}  src={outlook} style={{ justifyContent: "center", padding:"20px", cursor:'pointer', maxHeight:'60%', maxWidth:'60%'}}/></Box>
              <Box style={styles.OlookMessage}>
                Lorem ipesum is simpely text of printing and typesetting
                industery.
              </Box>
              <Box style={styles.OlookMessage1}>
                Lorem ipsusm simpley dummy text
              </Box>
              <Button style={styles.stylesBtn} onClick={this.hadleButtonClick} data-test-id="btn">Lorem Ipsum</Button>
            </Box>
          </Box>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
    main: {
      justifyContect: "center",
      textAlign: "center",
      width: "700px",
      height: "400px",
      backgroundColor:'#ffffff'
      
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      fontSize: "18px",
      fontWeight: "bolder",
     paddingLeft: "15px",
     fontFamily: 'Arial, sans-serif',
},
    center: {
      display: "flex",
      marginTop: "20px",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "200px",
      fontSize: "80px",
      color: "#1d74ba"
    },
    OlookMessage: {
      justifyContect: "center",
      textAlign: "center",
      marginTop: "-5px",
      fontSize: "15px",
      maxWidth: "700px",
      marginRight: "15px",
      marginLeft: "15px",
      color: " #999999",
      fontFamily: 'Arial, sans-serif',
        },
    OlookMessage1: {
      justifyContect: "center",
      textAlign: "center",
      fontSize: "15px",
      maxWidth: "700px",
      marginRight: "15px",
      marginLeft: "15px",
      color: " #999999",
      fontFamily: 'Arial, sans-serif',
    },
    stylesBtn: {
      padding: "10px",
      marginTop: "45px",
      width: "220px",
      backgroundColor: "#a14fec",
      color: "white",
      textTransform: "capitalize",
      borderRadius: "5px",
      justifyContent: "center",
      textAlign: "center",
      position: "relative",
      cursor: "pointer",
      fontSize:'15px'
    }
  };
  
// Customizable Area End
