import React from "react";
import AdvancedSearchControllerWeb, { Props } from "./AdvancedSearchController.web";
// Customizable Area Start
import {
    Box,
    Switch,
    OutlinedInput,
    InputAdornment,
    Select,
    Button,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Drawer,
    Avatar,
    Dialog
} from "@material-ui/core";
import { filterIcon, searchIcon, searchWhite, closeIcon, viewMoreIcon } from "./assets";
import { downArrow } from "../../dashboard/src/assets";
import CustomDataTable from "../../../components/src/CustomDataTable.web";
import ActiveCandidateList from "../../../components/src/AdvanceSearchCommon/ActiveCandidateList.web";
import FinalSelectionList from "../../../components/src/AdvanceSearchCommon/FinalSelectionList.web";
import HeaderWeb from "../../../components/src/Header.web";
// Customizable Area End

export default class AdvancedSearch extends AdvancedSearchControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    handleTblTwoSelection = (data: any) => {
        this.setState({ tblTwoSelectedRow: [] }, () => {
            this.setState({ tblTwoSelectedRow: data });
        });
    }

    handleDialogClose = (data?: string) => {
        if (data === "edit") {
            this.setState({ dialogStatus: false, drawerStatus: false })
        } else {
            this.setState({ dialogStatus: false });
        }
    }

    renderFilterLocationFields = () => {
        return (<>
            <Select
                displayEmpty
                placeholder="Select State"
                className="location-select"
                id="location-select"
                IconComponent={() => <img src={downArrow} className="select-arrow" width={12} />}
                style={{ marginBottom: 15 }}
                value={this.state.location?.state}
                onChange={(e: any) => this.handleFilterLocationState(e)}
                renderValue={() => !this.state.location.state ? "Select State" : this.state.location.state}
            >
                {this.state.stateList.length > 0 ?
                    this.state.stateList.map((item: any) =>
                        <option value={item.name} key={item.id}>{item.name}</option>
                    ) : (
                        <option value={""} disabled>No states found.</option>
                    )
                }
            </Select>
            <Select
                displayEmpty
                placeholder="Select City"
                className="location-select"
                id="location-select"
                value={this.state.location?.city}
                IconComponent={() => <img src={downArrow} className="select-arrow" width={12} />}
                onChange={(e: any) => this.handleFilterLocationCity(e)}
                renderValue={() => !this.state.location.city ? "Select City" : this.state.location.city}
                disabled={!this.state.location.state}
            >
                {this.state.cityList.length > 0 ?
                    this.state.cityList.map((item: any) =>
                        <option value={item.name} key={item.id}>{item.name}</option>
                    ) : (
                        <option value={""} disabled>No cities found.</option>
                    )
                }
            </Select>

        </>);
    }

    renderFormLocationFields = () => {
        return (<>
            <Select
                defaultValue={"1"}
                name="location"
                id="location_state"
                onChange={(e: any) => this.handleFormStateField(e)}
            >
                <option value={"1"} disabled>Select State</option>
                {this.state.stateList.length > 0 ?
                    this.state.stateList.map((item: any) =>
                        <option value={item.name} key={item.id}>{item.name}</option>
                    ) : (
                        <option value={""} disabled>No states found.</option>
                    )
                }
            </Select>
            <Select
                defaultValue={"1"}
                name="location"
                id="location_city"
                disabled={!this.state.is_formCityActive}
            >
                <option value={"1"} disabled>Select City</option>
                {this.state.cityList.length > 0 ?
                    this.state.cityList.map((item: any) =>
                        <MenuItem value={item.name} key={item.id}>{item.name}</MenuItem>
                    ) : (
                        <MenuItem value="" disabled>No cities found.</MenuItem>
                    )
                }
            </Select>
        </>);
    }

    render() {
        // Customizable Area Start
        return (
            <div className="advancedsearch-page" id="Main">
                <HeaderWeb />
                <div className="page-top">
                    <div className="header">
                        <h2>Candidate Search</h2>
                        <div className="toggle">
                            <span>{this.state.booleanSearch ? "Enable Boolean Search" : "Disable Boolean Search"}</span>
                            <Switch size="small" color="primary" id="search-switch" onChange={(e: any) => this.handleSearchForm(e)} />
                        </div>
                    </div>
                    <form
                        className={`search-form ${this.state.booleanSearch && "active"}`}
                        onSubmit={(e) => e.preventDefault()}
                        id="candidate-search"
                    >
                        <OutlinedInput
                            type="text"
                            name="search-input"
                            className="search-field"
                            placeholder="Keywords result for :“Executive” or “Technical” or “Administrative”"
                            date-testid={"dropDowntestID"}
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={searchIcon} width={20} />
                                </InputAdornment>
                            }
                        />
                        {!this.state.booleanSearch &&
                            (<>
                                <div className="flex-row">
                                    <div className="field">
                                        <label htmlFor="clearenceLevel">Clearance Level :</label>
                                        <Select
                                            defaultValue="1"
                                            onChange={(e: any) => this.handleClearanceLevelSelect(e.target.value)}
                                            name="clearenceLevel"
                                            id="clearenceLevel"
                                        >
                                            <option value="1">Select Clearance Level</option>
                                            <option value="PublicTrust">PublicTrust</option>
                                            <option value="Secret">Secret</option>
                                            <option value="top-Secret">Top Secret</option>
                                            <option value="TS/SCI">TS/SCI</option>
                                            <option value="TS/SCI-ciPoly">TS/SCI-ciPoly</option>
                                            <option value="TS/SCI-fsp">TS/SCI-fsp</option>
                                        </Select>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="jobPlateform">Job Platforms :</label>
                                        <Select
                                            displayEmpty
                                            name="jobPlateform"
                                            id="jobPlateform"
                                            defaultValue={"1"}
                                        >
                                            <option value={"1"} disabled>Select Job Platform</option>
                                            <option value="opt1">LinkedIn</option>
                                            <option value="opt2">Indeed</option>
                                            <option value="opt3">Clearancejob.com</option>
                                        </Select>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="experience">Experience :</label>
                                        <Select
                                            defaultValue={"1"}
                                            name="experience"
                                            id="experience"
                                        >
                                            <option value={"1"} disabled>Select Experience Level</option>
                                            <option value="opt1">1-2 years</option>
                                            <option value="opt2">3-4 years</option>
                                            <option value="opt3">5-6 years</option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="field-multi">
                                        <label htmlFor="location">Location :</label>
                                        {this.renderFormLocationFields()}
                                    </div>
                                    <div className="field-profile">
                                        <label htmlFor="profilePeriod">Profile Active :</label>
                                        <Select
                                            defaultValue={"1-90days"}
                                            name="location"
                                            id="location"
                                        >
                                            <option value="1-90days">1-90 days</option>
                                            <option value="90-180days">90-180 days</option>
                                            <option value="365days">181-365 days</option>
                                        </Select>
                                    </div>
                                    <Button type="submit" hidden={!this.state.booleanSearch}>
                                        <img src={searchWhite} width={17} />search
                                    </Button>
                                </div>
                            </>)
                        }
                        {this.state.booleanSearch &&
                            <Button type="submit"><img src={searchWhite} width={17} />search</Button>
                        }
                    </form>
                </div>
                <div className="page-content" >
                    <Box className="filter-sidebar" style={{ maxHeight: this.state.booleanSearch ? "710px" : "" }}>
                        <div className="header">
                            <h3>Search filter</h3>
                            <Button id="clearFilters-btn" onClick={() => this.handleClearFilters()}>Clear Filters</Button>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Job Platform</span>
                            <div className="options">
                                <FormControlLabel
                                    value="all"
                                    control={<Checkbox size="small" />}
                                    label="All"
                                    labelPlacement="end"
                                    id="selcetAll"
                                    className="checkbox-control"
                                    checked={this.state.jobPlateform.includes("all")}
                                    onChange={(e: any) => this.handleCheckboxChangejobPlateform(e)}
                                />
                                <FormControlLabel
                                    value="linkedIn"
                                    control={<Checkbox size="small" />}
                                    label="LinkedIn"
                                    id="selectlinkedIn"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    checked={this.state.jobPlateform.includes("linkedIn")}
                                    onChange={(e: any) => this.handleCheckboxChangejobPlateform(e)}
                                />
                                <FormControlLabel
                                    value="indeed"
                                    control={<Checkbox size="small" />}
                                    label="Indeed"
                                    labelPlacement="end"
                                    id="selectIndeed"
                                    className="checkbox-control"
                                    checked={this.state.jobPlateform.includes("indeed")}
                                    onChange={(e: any) => this.handleCheckboxChangejobPlateform(e)}
                                />
                                <FormControlLabel
                                    value="Clearancejob.com"
                                    control={<Checkbox size="small" />}
                                    label="Clearancejob.com"
                                    labelPlacement="end"
                                    id="selectClearancejob"
                                    className="checkbox-control"
                                    checked={this.state.jobPlateform.includes("Clearancejob.com")}
                                    onChange={(e: any) => this.handleCheckboxChangejobPlateform(e)}
                                />
                            </div>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Job Type</span>
                            <div className="options">
                                <FormControlLabel
                                    value="Executive"
                                    control={<Checkbox size="small" />}
                                    label="Executive"
                                    labelPlacement="end"
                                    id="SelectExecutive"
                                    className="checkbox-control"
                                    checked={this.state.jobType.includes("Executive")}
                                    onChange={(e: any) => this.handleCheckboxChangejobType(e)}
                                />
                                <FormControlLabel
                                    value="Technical"
                                    control={<Checkbox size="small" />}
                                    label="Technical"
                                    labelPlacement="end"
                                    id="selectTechnical"
                                    className="checkbox-control"
                                    checked={this.state.jobType.includes("Technical")}
                                    onChange={(e: any) => this.handleCheckboxChangejobType(e)}
                                />
                                <FormControlLabel
                                    value="Administrative"
                                    control={<Checkbox size="small" />}
                                    className="checkbox-control"
                                    labelPlacement="end"
                                    id="selectAdministrative"
                                    checked={this.state.jobType.includes("Administrative")}
                                    onChange={(e: any) => this.handleCheckboxChangejobType(e)}
                                    label="Administrative"
                                />
                                <FormControlLabel
                                    value="Management"
                                    control={<Checkbox size="small" />}
                                    label="Management"
                                    labelPlacement="end"
                                    id="selectManagement"
                                    className="checkbox-control"
                                    checked={this.state.jobType.includes("Management")}
                                    onChange={(e: any) => this.handleCheckboxChangejobType(e)}
                                />
                                <FormControlLabel
                                    value="Intelligence"
                                    control={<Checkbox size="small" />}
                                    label="Intelligence"
                                    id="selectIntelligence"
                                    className="checkbox-control"
                                    labelPlacement="end"
                                    checked={this.state.jobType.includes("Intelligence")}
                                    onChange={(e: any) => this.handleCheckboxChangejobType(e)}
                                />
                            </div>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Clearance Level</span>
                            <div className="options">
                                <FormControlLabel
                                    value="Public Trust"
                                    control={<Checkbox size="small" />}
                                    label="Public Trust"
                                    labelPlacement="end"
                                    id="selectPublicTrust"
                                    className="checkbox-control"
                                    checked={this.state.clearanceLevel.includes("Public Trust")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    value="Secret"
                                    control={<Checkbox size="small" />}
                                    label="Secret"
                                    labelPlacement="end"
                                    id="selectSecret"
                                    className="checkbox-control"
                                    checked={this.state.clearanceLevel.includes("Secret")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    value="Top secret"
                                    control={<Checkbox size="small" />}
                                    className="checkbox-control"
                                    id="selectTopSecret"
                                    labelPlacement="end"
                                    label="Top Secret"
                                    checked={this.state.clearanceLevel.includes("Top secret")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    label="TS/SCI"
                                    control={<Checkbox size="small" />}
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    id="selectTSSCI"
                                    value="TS/SCI"
                                    checked={this.state.clearanceLevel.includes("TS/SCI")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    label="TS/SCI CI Poly"
                                    value="TS/SCI CI Poly"
                                    id="selectTSSCIciPoly"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    checked={this.state.clearanceLevel.includes("TS/SCI CI Poly")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    label="TS/SCI FSP"
                                    id="selectTSSCIFSP"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    value="TS/SCI FSP"
                                    checked={this.state.clearanceLevel.includes("TS/SCI FSP")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    label="Undefined"
                                    id="selectUndefined"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    value="Undefined"
                                    checked={this.state.clearanceLevel.includes("Undefined")}
                                    onChange={(e: any) => this.handleCheckboxChangeclearanceLevel(e)}
                                />
                            </div>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Location</span>
                            <div className="options">
                                {this.renderFilterLocationFields()}
                            </div>
                        </div>
                        <div className="filter-topic">
                            <div className="title">Active</div>
                            <div className="options">
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    value="1-90 days"
                                    label="1-90 days"
                                    id="select1-90days"
                                    className="checkbox-control"
                                    labelPlacement="end"
                                    checked={this.state.activeStatus.includes("1-90 days")}
                                    onChange={(e) => this.handleCheckboxChangeactiveStatus(e)}

                                />
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    label="91-180 days"
                                    value="91-180 days"
                                    id="select91-180days"
                                    className="checkbox-control"
                                    labelPlacement="end"
                                    checked={this.state.activeStatus.includes("91-180 days")}
                                    onChange={(e) => this.handleCheckboxChangeactiveStatus(e)}
                                />
                                <FormControlLabel
                                    control={<Checkbox size="small" />}
                                    label="181-365 days"
                                    value="181-365 days"
                                    id="select181-365days"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    checked={this.state.activeStatus.includes("181-365 days")}
                                    onChange={(e) => this.handleCheckboxChangeactiveStatus(e)}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box className="candidate-block">
                        <div className="header">
                            <h3>Active Candidates</h3>
                            <div className="tbl-actions">
                                {!this.state.booleanSearch && <span>Select candidate to skill to a client </span>}
                                <Button
                                    className="confirm-btn"
                                    id="confirmBtn"
                                    onClick={() => this.handleDrawerOpen()}
                                    disabled={this.state.tblOneSelectedRow.length > 0 ? false : true}
                                >
                                    Add to client
                                </Button>
                                <button className="sort-btn">
                                    <img src={filterIcon} width={16} />
                                    <span>Sort by</span>
                                </button>
                            </div>
                        </div>
                        <ActiveCandidateList selectedData={(data: any) => this.handleTblOneSelection(data)} dataArray={this.state.candidateList} recordsPerPage={this.state.booleanSearch ? 16 : 14} />
                    </Box>
                </div>

                <Drawer
                    className='drawer-container'
                    id="drawer-id"
                    variant="temporary"
                    anchor="right"
                    hideBackdrop={false}
                    transitionDuration={500}
                    open={this.state.drawerStatus}
                    onClose={() => this.setState({ drawerStatus: false })}
                >
                    <div className="addCompany-block">
                        <div className="candidate-overview">
                            <div className="row">
                                <h3>Candidate Selected</h3>
                                <Button onClick={() => this.setState({ drawerStatus: false })} id="candidateSelected"><img src={closeIcon} width={18} /></Button>
                            </div>
                            <div className="row">
                                {this.state.selectedCandidateList.length > 0 && this.state.selectedCandidateList.map((item: any, index: number) =>
                                    <div className="candidate-box" key={item}>
                                        <Avatar src={""} alt={""} />
                                        <span>{item?.attributes?.candidate_name}</span>
                                    </div>
                                )}
                                <div className="candidate-box round">
                                    <div className="img-wrapper" id="addmore-btn" onClick={() => this.handleDialogClose("edit")}>
                                        <img src={closeIcon} width={15} style={{ transform: "rotate(45deg)" }} />
                                    </div>
                                    <span>Add More</span>
                                </div>
                                {this.state.viewMore &&
                                    <div className="candidate-box round" id="viewmore-id" onClick={() => this.handleDrawerOpen()}>
                                        <div className="img-wrapper">
                                            <img src={viewMoreIcon} width={20} />
                                        </div>
                                        <span>View More</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="title-block">
                            <h3>Select Client {"(" + this.state.tblTwoSelectedRow.length + ")"}</h3>
                            <button>
                                <span>Filter</span>
                                <img src={filterIcon} width={17} />
                            </button>
                        </div>
                        <div className="clientsearch-block">
                            <form id="clientSearch-form" onSubmit={(e: any) => this.onSearchCompanySubmit(e)}>
                                <OutlinedInput
                                    type="text"
                                    className="client-search"
                                    id="client-search"
                                    placeholder="Search Client Company"
                                    value={this.state.searchCompanyTxt}
                                    onChange={(e: any) => this.onSearchCompanyChange(e)}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={searchIcon} width={18} />
                                            <button type="submit" hidden></button>
                                        </InputAdornment>
                                    }
                                />
                            </form>
                            <Button className="done-btn" id="clientselection-btn" onClick={() => this.getFinalSelectionList()}>Done</Button>
                        </div>
                        <div className="companylist-tbl">
                            <CustomDataTable
                                data={this.state.companyList}
                                columns={[
                                    { header: "Company", dataKey: "Company" },
                                    { header: "Clearance", dataKey: "Clearance" },
                                    { header: "Job Type", dataKey: "Job_Type" },
                                    { header: "Salary", dataKey: "Salary" },
                                    { header: "Submitted", dataKey: "Submitted" },
                                ]}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                onSelect={this.handleTblTwoSelection}
                                isExpanded
                                expandedData={this.state.comExpandedData}
                                selectedData={this.state.tblTwoSelectedRow}
                                resetPagination={this.state.resetCompanytblpage}
                            />
                        </div>
                    </div>
                </Drawer>

                <Dialog
                    id="finalList-dialog"
                    open={this.state.dialogStatus}
                    onClose={() => this.setState({ dialogStatus: false })}
                    className="finalList-dialog"
                >
                    <FinalSelectionList finalList={this.state.finalSelctionList} dataArray={[this.state.tblOneSelectedRow, this.state.tblTwoSelectedRow]} dialogAction={this.handleDialogClose} />
                </Dialog>
            </div>
        );
        // Customizable Area End
    }
}