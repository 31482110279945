import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ApiCall } from "../../../components/src/ApiCall.web";
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  location?: any;
  history?: any;
  id?: string;
  selectedData?: any;
  finalList?: any;
  dataArray?: any;
  dialogAction?: any;
  recordsPerPage?: any;
}

interface S {
  // Customizable Area Start 
  drawerStatus: boolean;
  dialogStatus: boolean;
  companyList: any[];
  candidateList: any[];
  comExpandedData: any;
  tableOnePage: number;
  tblOneSelectedRow: any;
  tblTwoSelectedRow: any;
  searchCompanyTxt: string;
  jobType: any[];
  clearanceLevel: any[];
  location: {
    state: string,
    city: string,
  };
  jobPlateform: any[];
  activeStatus: any[];
  finalSelctionList: any[];
  confirmList: any,
  tblOneAgainSelectedRow: any;
  tblTwoAgainSelectedRow: any;
  openNotification: boolean;
  selectedCandidateList: any;
  viewMore: boolean;
  booleanSearch: boolean;
  resetCompanytblpage: number | null;
  stateList: any[];
  cityList: any[];
  is_formCityActive: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdvancedSearchControllerWeb extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  getCandiadteCallId: any;
  getCompanyListCallId: any;
  getFinalListCallId: any;
  postConfirmCallId: any;
  getStateListCallId: any;
  getCityListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      drawerStatus: false,
      dialogStatus: false,
      companyList: [],
      candidateList: [],
      comExpandedData: [],
      tableOnePage: 1,
      tblOneSelectedRow: [],
      tblTwoSelectedRow: [],
      searchCompanyTxt: "",
      jobType: [],
      clearanceLevel: [],
      location: {
        state: "",
        city: "",
      },
      jobPlateform: [],
      activeStatus: [],
      confirmList: "",
      finalSelctionList: [],
      tblOneAgainSelectedRow: [],
      tblTwoAgainSelectedRow: [],
      openNotification: false,
      selectedCandidateList: [],
      viewMore: false,
      booleanSearch: false,
      resetCompanytblpage: null,
      stateList: [],
      cityList: [],
      is_formCityActive: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getCandidateList();
    this.getCompanyList();
    this.getFilterStates();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (this.getCandiadteCallId === apiRequestCallId) {
        this.getCandidateListApiProcess(responseJson);
      }

      if (this.getCompanyListCallId === apiRequestCallId) {
        this.getCompaniesApiProcess(responseJson);
      }

      if (this.getFinalListCallId === apiRequestCallId) {
        this.getFinalListApiProcess(responseJson);
      }

      if (this.postConfirmCallId === apiRequestCallId) {
        this.setState({
          confirmList: responseJson,
          openNotification: true,
          dialogStatus: false,
          drawerStatus: false,
          tblOneSelectedRow: [],
          tblTwoSelectedRow: []
        });
      }

      this.remainingApiProcess(responseJson, apiRequestCallId);
    }
    // Customizable Area End
  }



  // Customizable Area Start
  handleClearanceLevelSelect = (selectedValues: any) => {
    this.setState({ clearanceLevel: selectedValues });
  }

  handleFormStateField = (e: any) => {
    const { stateList } = this.state;
    const selectedState = stateList?.find(item => item.name === e.target.value);
    if (selectedState) {
      this.getFilterCities(selectedState?.id);
      this.setState({is_formCityActive: true});
    } else {
      this.setState({is_formCityActive: false});
    }
  }

  remainingApiProcess = (responseJson: any, apiRequestCallId: any) => {
    if (this.getStateListCallId === apiRequestCallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({ stateList: responseJson?.states });
      }
    }

    if (this.getCityListCallId === apiRequestCallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({ cityList: responseJson?.cities });
      }
    }
  }

  getCandidateListApiProcess = (responseJson: any) => {
    if (responseJson.candidates) {
      if (responseJson.candidates?.data?.length > 0) {
        this.setState({ candidateList: responseJson.candidates.data });
      } else {
        this.setState({ candidateList: [] });
      }
    }
  }

  getCompaniesApiProcess = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      const expandedData = responseJson.map((item: any) => [{
        "Location": item.Location ? item.Location : "-",
        "Available Dates": item.Available_Dates ? item.Available_Dates : "-"
      }]);
      this.setState({ companyList: [], comExpandedData: [] }, () => {
        this.setState({ companyList: responseJson, comExpandedData: expandedData });
      });
    }
  }

  getFinalListApiProcess = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      this.setState({ finalSelctionList: responseJson }, () => {
        this.setState({ tblOneSelectedRow: this.state.tblOneSelectedRow, tblTwoSelectedRow: this.state.tblTwoSelectedRow, dialogStatus: true });
      });
    }
  }

  handleTblOneSelection = (data: any) => {
    this.setState({ tblOneSelectedRow: data });
  }

  handleClearFilters = () => {
    this.setState({
      jobType: [],
      jobPlateform: [],
      clearanceLevel: [],
      activeStatus: [],
      location: { state: "", city: "" },
    }, () => { this.getCandidateList() });
  }

  handleFilterLocationState = (e: any) => {
    const { location, stateList } = this.state;
    const selectedState = stateList.find(item => item.name === e.target.value);

    this.setState({
      ...this.state,
      location: { state: e.target.value, city: location.city } 
    }, () => {
      this.getFilterCities(selectedState?.id);
      this.getCandidateList();
    });
  }

  handleFilterLocationCity = (e: any) => {
    this.setState({
      ...this.state,
      location: { city: e.target.value, state: this.state.location.state }
    }, () => { this.getCandidateList() });
  }

  handleCheckboxChangejobType = (e: any) => {
    const { jobType } = this.state;
    const { value, checked } = e.target;

    if (checked) {
      this.setState({ jobType: [...jobType, value] }, () => { this.getCandidateList() });
    } else {
      if (jobType.length > 0) {
        const updatedJobType = jobType.filter(item => item !== value);
        this.setState({ jobType: updatedJobType }, () => { this.getCandidateList() });
      }
    }
  }

  handleCheckboxChangeclearanceLevel = (e: any) => {
    const { clearanceLevel } = this.state;
    const { value, checked } = e.target;

    if (checked) {
      this.setState({ clearanceLevel: [...clearanceLevel, value] }, () => { this.getCandidateList() });
    } else {
      if (clearanceLevel.length > 0) {
        const updatedClearanceLevel = clearanceLevel.filter(item => item !== value)
        this.setState({ clearanceLevel: updatedClearanceLevel }, () => { this.getCandidateList() });
      }
    }
  }

  handleCheckboxChangejobPlateform = (e: any) => {
    const { jobPlateform } = this.state;
    const { value, checked } = e.target;

    if (checked) {
      this.setState({ jobPlateform: [...jobPlateform, value] }, () => { this.getCandidateList() });
    } else {
      if (jobPlateform.length > 0) {
        const updatedJobPlateform = jobPlateform.filter((item: any) => item !== value);
        this.setState({ jobPlateform: updatedJobPlateform }, () => { this.getCandidateList() });
      }
    }
  }

  handleCheckboxChangeactiveStatus = (e: any) => {
    const { activeStatus } = this.state;
    const { value, checked } = e.target;

    if (checked) {
      this.setState({ activeStatus: [...activeStatus, value] },() => { this.getCandidateList() });
    } else {
      if (activeStatus.length > 0) {
        const updatedActiveStatus = activeStatus.filter((item: any) => item !== value)
        this.setState({ activeStatus: updatedActiveStatus },() => { this.getCandidateList() });
      }
    }
  }

  onSearchCompanySubmit = (e: any) => {
    e.preventDefault();
    this.setState({ resetCompanytblpage: 0 });
    if (this.state.searchCompanyTxt) {
      this.getCompanyList();
    }
  }

  onSearchCompanyChange = (e: any) => {
    this.setState({ searchCompanyTxt: e.target?.value }, () => {
      if (this.state.searchCompanyTxt === "") {
        this.getCompanyList();
      }
    });
  }

  getFilterStates = async () => {
    const token = localStorage.getItem("token");

    this.getStateListCallId = await ApiCall({
      contentType: configJSON.httpContentType,
      token: token,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.defaultUrlForCityState
    });
  }

  getFilterCities = async (stateId: number) => {
    const token = localStorage.getItem("token");

    this.getCityListCallId = await ApiCall({
      contentType: configJSON.httpContentType,
      token: token,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.defaultUrlForCityState + `/${stateId}/cities`
    });
  }

  getCandidateList = async () => {
    const token = localStorage.getItem("token");
    const { clearanceLevel, location, jobPlateform, jobType, activeStatus } = this.state;

    const minActive = activeStatus[0]?.split("-")[0];
    const abc = activeStatus[activeStatus.length - 1]?.split("-")[1];
    const maxActive = abc?.split(" ")[0];

    const params = new URLSearchParams();
    jobPlateform.forEach((item:any) => params.append("job_platform[]", item));
    jobType.forEach((item:any) => params.append("job_type[]", item));
    params.append("min_experience", "");
    params.append("max_experience", "");
    params.append("state", location.state);
    params.append("city", location.city);
    params.append("min_active", minActive ? minActive : "");
    params.append("max_active", maxActive ? maxActive : "");
    if (clearanceLevel.length > 0) {
      clearanceLevel.forEach((item: any) => params.append("clearence_level[]", item));
    } else {
      params.append("clearence_level", "");
    }

    this.getCandiadteCallId = await ApiCall({
      contentType: configJSON.httpContentType,
      token: token,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.defaultUrlForFilter + `?${params}`
    });
  }

  getCompanyList = async () => {
    const token = localStorage.getItem("token");

    this.getCompanyListCallId = await ApiCall({
      contentType: configJSON.httpContentType,
      token: token,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.EndPointCompanyList + `?search=${this.state.searchCompanyTxt}`,
    })
  }

  getFinalSelectionList = async () => {
    console.log("===@@@")
    const { tblOneSelectedRow, tblTwoSelectedRow } = this.state;

    if (tblOneSelectedRow.length > 0 && tblTwoSelectedRow.length > 0) {
      const token = localStorage.getItem("token");

      this.setState({ tblOneAgainSelectedRow: this.state.tblOneSelectedRow, tblTwoAgainSelectedRow: this.state.tblTwoSelectedRow })

      const params = new URLSearchParams();
      this.state.tblOneSelectedRow.map((item: any) => params.append("candidate_ids[]", item));
      this.state.tblTwoSelectedRow.map((item: any) => params.append("post_ids[]", item));

      this.getFinalListCallId = await ApiCall({
        contentType: configJSON.httpContentType,
        token: token,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getFinalSelectedListApiEndPoint + `?${params}`,
      })

    }
  }

  postConfirmList = async (data: any) => {

    let asd = data[0];
    let dsa = data[1]

    const body = {
      "candidate_ids": asd,
      "post_ids": dsa,
    }

    const token = localStorage.getItem("token");

    this.postConfirmCallId = await ApiCall({
      contentType: configJSON.httpContentType,
      token: token,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.EndPointConfirmList,
      body: body
    })
  }

  handleClose = () => {
    this.setState({ dialogStatus: false });
  }

  handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openNotification: false });
  };
  // Customizable Area End
  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson || responseJson.errors;
  };

  handleSearchForm = (e: any) => {
    this.setState({ booleanSearch: e.target.checked })
  }



  handleDrawerOpen = () => {
    const { tblOneSelectedRow, candidateList, viewMore } = this.state;

    let selectedCandidateData = [];
    if (tblOneSelectedRow.length > 5 && !viewMore) {
      selectedCandidateData = candidateList.filter(item => tblOneSelectedRow.includes(item.id)).slice(0, 5);
      this.setState({ viewMore: true });
    } else {
      selectedCandidateData = candidateList.filter(item => tblOneSelectedRow.includes(item.id));
      this.setState({ viewMore: false });
    }

    this.setState({ drawerStatus: true, selectedCandidateList: selectedCandidateData });
  }
  // Customizable Area End

}
