import React from "react";

// Customizable Area Start
import {
  Avatar,
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  IconButton,
  Drawer,
  List,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderWeb from "../../../components/src/Header.web";
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { userProfile } from "./assets";
import InputAdornment from "@material-ui/core/InputAdornment";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
      }}
      role="presentation"
      onClick={this.toggleDrawer}
      onKeyDown={this.toggleDrawer}
    >
      <Box style={webStyle.userProfileWrapper}>
        <img src={userProfile} style={webStyle.userProfile} />
        <Typography variant="h6">{configJSON.userName}</Typography>
        <Typography variant="h6" style={webStyle.userDesignation}>
          {configJSON.userDesignation}
        </Typography>
        <Button
          onClick={this.toggleDrawer}
          variant="text"
          style={webStyle.logout}
        >
          {configJSON.logout}
        </Button>
      </Box>
      <Divider />
      <List>
        {this.state.drawerItems?.length > 0 &&
          this.state.drawerItems?.map((item: any) => {
            let data = item?.data?.attributes;

            if (data?.position !== "left") {
              return null;
            }

            return (
              <React.Fragment key={data?.id}>
                {data?.items?.map((mItem: any) => {
                  return (
                    <ListItem
                      button
                      key={mItem?.id?.toString()}
                      onClick={() => this.onPressMenuItem(mItem)}
                    >
                      <ListItemIcon>
                        <img src={item.icon} style={webStyle.drawerItemIcon} />
                      </ListItemIcon>
                      <ListItemText primary={mItem?.name} />
                    </ListItem>
                  );
                })}
              </React.Fragment>
            );
          })}
      </List>
    </div>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={{
        width: "100vw",
        height: '100vh',
        padding: 0,
        margin: 0,
      }}>
        <HeaderWeb />
        <Box style={{
          height: '70vh',
          width: '90vw',
          padding: '1rem 4rem',
        }}>
          <Typography>DashBoard</Typography>

          <Box sx={{
            display: 'flex',
            flexDirection: "column",
          }}>
            <Typography style={{
              fontSize: '0.75rem',
            }}>Search By Skills</Typography>
            <Box style={{
              display: 'grid',
              gridTemplateColumns: '7fr 3fr'
            }}>
              <Input style={{
                border: '1px solid black',
                padding: '0.25rem 0.75rem',
                borderRadius: '0.5rem',
              }}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              <Button style={{
                backgroundColor: 'red',
                color: 'white',
              }}>Search Client</Button>
            </Box>
          </Box>

          <Box style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr',
            gap: '1rem'
          }}>
            <Box>
              <Typography>Actual Clients</Typography>
              <Box style={{
                display: 'grid',
                gridTemplateColumns: '2fr 2fr 2fr 1fr',
              }}>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>See All</Box>
              </Box>
            </Box>

            <Box>
              <Typography>Actual Clients</Typography>
              <Box style={{
                display: 'grid',
                gridTemplateColumns: '2fr 2fr 2fr 1fr',
              }}>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>See All</Box>
              </Box>
            </Box>

            <Box>
              <Typography>Actual Clients</Typography>
              <Box style={{
                display: 'grid',
                gridTemplateColumns: '2fr 2fr 2fr 1fr',
              }}>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>Client 1</Box>
                <Box>See All</Box>
              </Box>
            </Box>

          </Box>

        </Box>

        <Box style={{
          height: '10vh',
          width: '100vw',
          backgroundColor: 'black',
          color: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0rem',
          margin: '0rem',
          position: 'absolute',
          bottom: 0
        }}>
          <Typography style={{ color: 'white' }}>iNTELLIGENCE</Typography>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  header: {

  }
};
// Customizable Area End
