import React from "react";
import { withRouter } from "react-router-dom";
import CandidateSearchController, { Props } from "./CandidateSearchController.web";
// Customizable Area Start
import {
    bagIcon,
    capIcon,
    closeIcon,
    dateIcon,
    downArrow,
    infoIcon1,
    locationIcon,
    redTick
} from "./../../src/assets"
import { OutlinedInput, Button, Box, MenuItem, FormControlLabel, Select, Checkbox, Avatar } from "@material-ui/core";
import "../../assets/css/CandidateSearch.web.css";
import 'flatpickr/dist/flatpickr.min.css';

// Customizable Area End
/* istanbul ignore next */
export class CandidateSearch extends CandidateSearchController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
render() {
 // Customizable Area Start
            const checkboxItems = [
            { value:this.state.ischeked.excative, label: "Executive" },
            { value:this.state.ischeked.technichal, label: "Technical" },
            { value:this.state.ischeked.admisntrative, label: "Administrative" },
            { value:this.state.ischeked.managment, label: "Management" },
            { value:this.state.ischeked.intelligence, label: "Intelligence" },
            { value:this.state.ischeked.publictruts, label: "Public Trust" },
            { value:this.state.ischeked.topsecreat, label: "Top Secret" },
            { value:this.state.ischeked.secreat, label: "Secret" },
            { value:this.state.ischeked.tsSci, label: "TS/SCI" },
            { value:this.state.ischeked.tsScipoly, label: "TS/SCI CI Poly" },
            { value:this.state.ischeked.tsScifcp, label: "TS/SCI CI FSP" },
            { value:this.state.ischeked.undefined, label: "UNDEFINED" },
            { value:this.state.ischeked.ontwoyear, label: "1-2 Years" },
            { value:this.state.ischeked.threetofounryear, label: "3-4 Years" },
            { value:this.state.ischeked.fievtosixyera, label: "5-6 Years" },
            { value:this.state.ischeked.nintydays, label: "Rank A" },
            { value:this.state.ischeked.oneeightdaya, label: "Rank B" },
            { value:this.state.ischeked.threesixtydaya, label: "Rank C" },
            { value:this.state.ischeked.threesixtyplus, label: "Rank D" },
        ];
return (
            <div className="candidate-search-page" id="Main">
                <div className="pageTop-section">
                    <div className="page-title">
                        <h1>Candidate Search</h1>
                        <span>Boolean search <img src={infoIcon1} width={18} /></span>
                    </div>
                    <div className="search-block">
                        <OutlinedInput
                            type="text"
                            id="candidate-search"
                            className="candidate-search"
                            placeholder="Search Candidate"
                        />
                        <div className="search-actions">
                            <Button className="editsearch-btn" >Modify Search</Button>
                            <Button className="newsearch-btn">New Search</Button>
                        </div>
                      <div className="search-items">
                            {Object.entries(checkboxItems).map(([key, value]) => {
                                if (value.value) {
                                    return (
                                        <div key={key}>
                                            <span className="tag">{value.label} <img width={10} /></span>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            <div><span className="tag" onClick={this.handleUncheck}>Clear All</span></div>

                        </div>
                    </div>
                </div>
                <div className="pageMiddle-section">
                    <Box className="filter-sidebar">
                        <div className="header">
                            <h2>Filter</h2>
                            <Button onClick={this.handleUncheck} data-test-Id="crearAll" >Clear Filter</Button>
                        </div>

                        <div className="filter-topic">
                            <span className="title">Job Type</span>
                            <div className="options">
                                <FormControlLabel data-test-id="job"
                                    value="Executive"
                                    control={<Checkbox size="small" checked={this.state.ischeked.excative} />}
                                    label="Executive" data-test-Id="ecexative"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangejobType("Executive")} />
                                <FormControlLabel data-test-Id="techichal"
                                    value="Technical"
                                    control={<Checkbox size="small" checked={this.state.ischeked.technichal} />}
                                    label="Technical"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangejobType("Technical")} />
                                <FormControlLabel
                                    value="Administrative" data-test-Id="adminstratiov"
                                    control={<Checkbox size="small" checked={this.state.ischeked.admisntrative} />}
                                    label="Administrative"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangejobType("Administrative")} />
                                <FormControlLabel
                                    value="Management"
                                    control={<Checkbox size="small" checked={this.state.ischeked.managment} />}
                                    label="Management" data-test-Id="managmnet"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangejobType("Management")}
                                />
                                <FormControlLabel
                                    value="Intelligence"
                                    control={<Checkbox size="small" checked={this.state.ischeked.intelligence} />}
                                    label="Intelligence"
                                    labelPlacement="end" data-test-Id="filed"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangejobType("Intelligence")}
                                />
                            </div>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Clearance Level</span>
                            <div className="options">
                                <FormControlLabel
                                    value="Public Trust"
                                    control={<Checkbox size="small" checked={this.state.ischeked.publictruts} />}
                                    label="Public Trust" data-test-Id="trust"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("Public Trust")} />
                                <FormControlLabel
                                    value="secret"
                                    control={<Checkbox size="small" checked={this.state.ischeked.secreat} />}
                                    label="Secret" data-test-Id="secreat"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("Secret")}
                                />
                                <FormControlLabel
                                    value="Top Secret"
                                    control={<Checkbox size="small" checked={this.state.ischeked.topsecreat} />}
                                    label="Top Secret" data-test-Id="topsecreat"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("Top secret")} />
                                <FormControlLabel
                                    value="TS/SCI"
                                    control={<Checkbox size="small" checked={this.state.ischeked.tsSci} />}
                                    label="TS/SCI" data-test-Id="sci"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("TS/SCI")} />
                                   <FormControlLabel
                                    value="TS/SCI-CI Poly"
                                    control={<Checkbox size="small" checked={this.state.ischeked.tsScipoly} />}
                                    label="TS/SCI CI Poly" data-test-Id="cipoly"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("TS/SCI CI Poly")} />
                                 <FormControlLabel
                                    value="TS/SCI-fsp"
                                    control={<Checkbox size="small" checked={this.state.ischeked.tsScifcp} />}
                                    label="TS/SCI FSP" data-test-Id="level"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("TS/SCI FSP")} />
                              <FormControlLabel
                                    value="Undefined"
                                    control={<Checkbox size="small" checked={this.state.ischeked.undefined} />}
                                    label="UNDEFINED" data-test-Id="undefiled"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeclearanceLevel("Undefined")} />
                                    </div>
                         </div>
                        <div className="filter-topic">
                            <span className="title">Years Experience</span>
                            <div className="options">
                                <FormControlLabel
                                    value="1,2"
                                    control={<Checkbox size="small" checked={this.state.ischeked.ontwoyear} />}
                                    label="1-2 years" data-test-Id="first"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeexpernece("1,2")} />
                                  <FormControlLabel
                                    value="3,4"
                                    control={<Checkbox size="small" checked={this.state.ischeked.threetofounryear} />}
                                    label="3-4 years" data-test-Id="third"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeexpernece("3,4")} />
                                   <FormControlLabel
                                    value="5,6"
                                    control={<Checkbox size="small" checked={this.state.ischeked.fievtosixyera} />}
                                    label="5-6 years"
                                    labelPlacement="end" data-test-Id="year"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeexpernece("5,6")} />
                              </div>
                        </div>
                        <div className="filter-topic">
                            <span className="title">Location</span>
                            <div className="options1">
                                <Select
                                    id="location-select"
                                    className="location-select" data-test-Id="location1"
                                    IconComponent={() =>
                                        <img src={downArrow} className="select-arrow" width={12} />
                                    } disableUnderline

                                    value={this.state.statenew}
                                    onChange={(event) => {
                                      const selectedState = event.target.value as string;
                                      this.handleCheckboxChangelocationState(selectedState);
                                      const stateId = this.state.allState.find((option:any) => option.name === selectedState)?.id;
                                      this.getCityData(stateId);

                                    }}
                                    displayEmpty
                                    
                                >
                                   <MenuItem value="" disabled>
                                        Select State{" "}</MenuItem>

                                    {this.state.allState.map((option:any) => (
                                  <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                                </Select>
                            </div>
                            <div className="options">
                                <Select
                                    id="location-select"
                                    className="location-select" data-test-Id="location"
                                    IconComponent={() =>
                                        <img src={downArrow} className="select-arrow" width={12} />
                                    }
                                    value={this.state.locationNew}
                                    onChange={(event: any) => this.handleCheckboxChangelocation(event.target.value)}
                                    displayEmpty
                                    disabled={this.state.isCityDisabled}

                                >
                                  <MenuItem value="" disabled>
                                        Select City{" "}</MenuItem>
                                 
                                        {this.state.allCity.length >= 1 ? 
                                        this.state.allCity.map((item:any) => 
                                            <MenuItem value={item.name} key={item.id}>{item.name}</MenuItem>
                                        ) : (
                                            <MenuItem value="" disabled>No cities found</MenuItem>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="filter-topic">
                            <div className="title">Active</div>
                            <div className="options">
                                <FormControlLabel
                                    value="1-90 days"
                                    control={<Checkbox size="small" checked={this.state.ischeked.nintydays} />}
                                    label="1-90 days" data-test-Id="nintydaya"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeactiveStatus("1,90")} />
                                   <FormControlLabel
                                    value="91-180 days"
                                    control={<Checkbox size="small" checked={this.state.ischeked.oneeightdaya} />}
                                    label="91-180 days"
                                    labelPlacement="end" data-test-Id="days"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeactiveStatus("91,180")} />
                                    <FormControlLabel
                                    value="181-365"
                                    control={<Checkbox size="small" checked={this.state.ischeked.threesixtydaya} />}
                                    label="181-365 days" data-test-Id="maxday"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeactiveStatus("181,365")} />
                                <FormControlLabel
                                    value="366"
                                    control={<Checkbox size="small" checked={this.state.ischeked.threesixtyplus} />}
                                    label="366+days" data-test-Id="maxday"
                                    labelPlacement="end"
                                    className="checkbox-control"
                                    onChange={() => this.handleCheckboxChangeactiveStatus("366")} />
                            </div>
                        </div>
                    </Box>
                    <div className="content-area">
                        <div className="list-actions">
                            <div>
                                <button className="selectall" style={{ cursor: 'pointer', color: this.state.colorChnage ? "#6200EE" : "" }} data-test-Id="SelcetAll" onClick={this.selectAllCandidates} >Select all</button>
                                <button className="select" style={{ cursor: 'pointer', color: this.state.colorChnage ? "#9C9C9C" : "#6200EE" }} >Select</button>
                                <button className="assign-btn">Assign Selected</button>
                            </div>
                            <div>
                                <button>Sort by</button>
                            </div>
                        </div>
                        <div className="candidate-list">

                        {this.state.candidateList.length === 0 ? (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                      <div style={{fontSize: '20px', color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <div>No candidates record found.</div>
                      </div>
                    </div>
) : (
  this.state.candidateList.slice(0, 80).map((item: any, index: number) => (
    <Box className={`candidate-box ${item.id}`} key={item.id}>
      <div className="box-top">
        <div
          className="imgaebox"
          data-test-Id="SelcethisCandidate"
          onClick={this.selctedThisCandidate.bind(this, item.id)}
        >
          {this.state.selectedCandidate.includes(item.id) && (
            <img src={redTick} className="redtick" />
          )}
        </div>
        <div className="profile">
          <Avatar src={""} alt={""} className="profile-img" />

          <div className="user-info">
            <small className="name">{item.candidate_name}</small>
            <span>{item.position}</span>
            <small className="active-status">Last active: 10 Days ago</small>
          </div>
        </div>
        <div className="other-info">
          <span>
            <img src={bagIcon} width={16} />{" "}
            {item.experience ? item.experience + " years" : "-"}
          </span>
          <span>
            <img src={locationIcon} width={12} />{" "}
            {item.city || item.state ? item.city + ", " + item.state : "-"}
          </span>
          <span>
            <img src={capIcon} width={18} /> Lorem Ipsum is simply
          </span>
        </div>
      </div>
      <div className="box-bottom">
        <div className="left">
          <div className="clearence-status">
            Clearance : {item.clearence_level ? item.clearence_level : "-"}
          </div>
          <div className="date">
            <div className="action-container">
              <img src={dateIcon} width={13} />
              <input
                type="text"
                id={`datepicker-${item.id}`}
                className="datetimepicker"
                placeholder="Select Date and Time Range"
                onClick={() => this.handleDatePicker(item.id)}
              />
            </div>
            <span>
              {this.formatDate(item.submission_date)} &{" "}
              {this.formatDate(item.interview_date)}
            </span>
          </div>
        </div>
        <div className="right">
          <span>
            View more <img src={downArrow} width={15} />
          </span>
        </div>
      </div>
    </Box>
  ))
)}

                        </div>
                    </div>
                </div>
            </div>
 );
        // Customizable Area End
    }
}

export default withRouter(CandidateSearch as any);
