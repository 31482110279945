import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo } from "./assets";
import './ForgotPassword.css'
import { Formik  } from 'formik';
import * as Yup from 'yup';

import ForgotPasswordController,{Props} from "./ForgotPasswordController";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });


  export default class ForgotPassword extends ForgotPasswordController {
    constructor(props:Props){
        super(props)
    }

    render(){
        return(
            <Box style={{
                margin:'0 rem',
                padding:'0 rem',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                height:'100vh'
              }}>
                <Box sx={{
                  width:'24rem',
                  bgcolor:'black',
                  color:'white',
                  display:'flex',
                  flexDirection:'column',
                  justifyContent:'center',
                  alignItems:'center',
                  padding:'1.5rem',
                  borderRadius:'0.5rem',
                }}>
                  <Box style={{width:'100%',}}>
                    <img style={{width:'100%', height:'100px',objectFit:'cover'}} src={logo}/>
                </Box>
            
                  {this.state.accountStatus!=="Confirmation"&&
                  <Typography 
                    className="fontStyle"
                    style={{
                        margin:'0.5rem',
                        fontSize:'1rem',
                        fontWeight:'normal',
                    }} 
                    variant="h6" 
                    component="h6"
                    >
                    Reset Password
                  </Typography>}

                {this.state.accountStatus=="Confirmation" &&<>
                <Typography className="fontStyle">Thank you, we have reset your password</Typography>
                <Box 
                  className="fontStyle"
                  style={{
                      color:'white',
                      backgroundColor: 'rgba(199, 0, 57, 1)',
                      padding:'0.5rem',
                      margin:'1rem',
                      width:'10rem',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center',
                      borderRadius:'0.5rem',
                      cursor:'pointer',
                      }}
                      onClick={()=>this.goToLogin()}
                      >
                        Sign In
                    </Box>
                </>}
                
                {this.state.accountStatus!=="Confirmation" &&
                <>
                <Formik
              validateOnChange
              validateOnBlur
              initialValues={{
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string().required('Password is required').matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,16}$/,'Password must contain one lowercase , one uppercase , one special character and one digit. Must be 8-16 characters long.'),
                confirmPassword:Yup.string().required('Confirm Password is required').oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
              })}
        
              onSubmit={(values: any) => 
                {
                  this.passwordChange(values)
                }
              }
            >
              {({ handleSubmit, handleChange ,values, touched, errors }:any) => (
          
          <form onSubmit={handleSubmit}>
              <Box sx={{ 
                padding: "0.5rem 1rem",
                width:'22rem',
                }}> 
                <Typography className="fontStyle">New Password</Typography>
                    <Input
                    name="newPassword"
                    style={{
                        backgroundColor:'white',
                        color:'black',
                        padding:'0.5rem',
                        borderRadius:'0.5rem',
                        border:`${this.state.newPassColor} 2px solid`
                    }}
                    error={errors.newPassword && touched.newPassword}
                    disableUnderline
                    onFocus={()=>this.setState({newPassColor:'yellow'})}
                    onBlur={()=>this.setState({newPassColor:'black'})
                                }
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField ? "text" : "password"}
                    fullWidth={true}
                    value={values?.newPassword}
                    onChange={handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>this.setState({enablePasswordField:!this.state.enablePasswordField})}
                            edge="end"
                        >
                            {this.state.enablePasswordField ? (
                            <Visibility style={{color:'black'}}/>
                            ) : (
                            <VisibilityOff style={{color:'black'}}/>
                            )}
                        </IconButton>
                        </InputAdornment>
                    }
                    />
                    {errors.newPassword && touched.newPassword&&<p className="fontStyle errorMessage">{errors.newPassword}</p>}
              </Box>

              <Box sx={{ 
                padding: "0.5rem 1rem" ,
                width:'22rem',
              }}> <Typography className="fontStyle">Confirm Password</Typography>
                    <Input
                    style={{
                        backgroundColor:'white',
                        color:'black',
                        padding:'0.5rem',
                        borderRadius:'0.5rem',
                        border:`${this.state.confPassColor} 2px solid`
                    }}
                    name="confirmPassword"
                    disableUnderline
                    onFocus={()=>this.setState({confPassColor:'yellow'})}
                    onBlur={()=>this.setState({confPassColor:'black'})}
                    data-test-id="txtInputPassword"
                    type={this.state.enableRePasswordField ? "text" : "password"}
                    fullWidth={true}
                    error={errors.confirmPassword && touched.confirmPassword}
                    value={values?.confirmPassword}
                    onChange={handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>this.setState({enableRePasswordField:!this.state.enableRePasswordField})}
                            edge="end"
                        >
                            {this.state.enableRePasswordField ? (
                            <Visibility style={{color:'black'}}/>
                            ) : (
                            <VisibilityOff style={{color:'black'}}/>
                            )}
                        </IconButton>
                        </InputAdornment>
                    }
                    />
                    {errors.confirmPassword && touched.confirmPassword&&<p className="fontStyle errorMessage">{errors.confirmPassword}</p>}
              </Box>
                    <Box style={{width:'100%', display:'flex',justifyContent:'center'}}>
                    <Button 
                      className="fontStyle"
                      style={{
                        color:'white',
                        backgroundColor: 'rgba(199, 0, 57, 1)',
                        padding:'0.5rem',
                        margin:'1rem',
                        width:'10rem',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius:'0.5rem',
                        cursor:'pointer'
                        }}
                        type="submit"  
                        >
                        Reset Password
                    </Button>
                    </Box>
                    </form>
              )}
                    </Formik>
                    </>
      }
                      
                </Box>
              </Box>
              
        )
    }

  }

// Customizable Area End
