import React from 'react';
import AdHocReportingController from "./AdHocReportingController.web";
import HeaderWeb from '../../../../components/src/Header.web';
import { Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, LinearProgress } from '@material-ui/core';
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined';
import { dateIcon, download, view_Positive, view_Growth } from '../assets'
import 'flatpickr/dist/flatpickr.min.css';
import { DataGrid } from '@material-ui/data-grid';
import Pagination from "@material-ui/lab/Pagination";
import {
  AreaChart,
  Area,
  XAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";



const columns = [
  {
    field: "id",
    headerName: "candidate id",
    flex: 0,
    editable: false,
    sortable: false
  },
  {
    field: "candidate_name",
    headerName: "candidate_name",
    flex: 1,
    editable: false,
    sortable: false,
  },
  {
    field: "position",
    headerName: "position",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "state",
    headerName: "state",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "salary",
    headerName: "Salary",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "clearence_level",
    headerName: "Clearance",
    flex: 1,
    editable: false,
    sortable: false
  },
];

export default class AdHocReporting extends AdHocReportingController {
  render() {
    let data = [
      {
        number: this.state.AdHocCountingData?.current_job_opening_count,
        containt: "Current Job Opening",
      },
      {
        number: this.state.AdHocCountingData?.candidates_for_feedback_count,
        containt: "Candidate for Feedback",
      },
      {
        number: this.state.AdHocCountingData?.candidates_expecting_offer_count,
        containt: "Candidate Expecting Offer",
      },
      {
        number: this.state.AdHocCountingData?.candidates_expecting_offer_count,
        containt: "Candidate Accepted Offer",
      },
    ]

    const paginatedRows = this.state.rows?.slice(
      (this.state.setPage - 1) * this.state.pageSize,
      this.state.setPage * this.state.pageSize
    );
    return (
      <Box className='ad_Hoc_outer_wrapper' id='Main'>
        <HeaderWeb />
        <Box className='ad_Hoc_Wrapper'>
          <h3>Ad Hoc Reporting</h3>
          <Box className='ad-hoc-reporting-wrapper'>
            {
              data.map((item: any) => {
                return (
                  <>
                    <Box className='job_opening_div'>
                      <Box className='job_opening_view_report'>View Report <CallMadeOutlinedIcon /></Box>
                      <Box className='job_opening_number'>
                        {item.number ? this.getData(item.number) : "00"}
                      </Box>
                      <Box className='job_opening_container color_cls'>{item.containt}</Box>
                    </Box>
                  </>
                )
              })
            }
          </Box>
          <Box className='report_selection_outer_wrapper_container'>
            <h4 >Select Report : </h4>
            <Box className='report_selection_inner_wrapper_container'>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      className='ad_hoc_checkbox'
                      value="current_job_opening"
                      defaultChecked
                    />
                  }
                  id="checkbox1"
                  onChange={(e: any) => this.handleCurrentJobOpeningCheckBox(e)}
                  className='checkbox_level'
                  label="Current Job opening"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="active_candidates"
                    />
                  }
                  id="checkbox2"
                  onChange={(e: any) => this.handleActiveCandidateCheckBox(e)}
                  className='checkbox_level'
                  label="Active Candidates"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="Inactive_Candidates"
                    />
                  }
                  id="checkbox3"
                  onChange={(e: any) => this.handleInactiveCandidateCheckBox(e)}
                  className='checkbox_level'
                  label="Inactive candidates"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="submitted_candidates"
                    />
                  }
                  id="checkbox4"
                  onChange={(e: any) => this.handleSubmitCandidatesCheckBox(e)}
                  className='checkbox_level'
                  label="Total No Of Candidates Submitted"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="Public Trust"
                    />
                  }
                  id="checkbox5"
                  onChange={(e: any) => this.handleClearanceLableCheckBox(e)}
                  className='checkbox_level'
                  label="Candidates By Clearance Level"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="interview_with_client"
                    />
                  }
                  id="checkbox6"
                  onChange={(e: any) => this.handleInterviewWithClientCheckBox(e)}
                  className='checkbox_level'
                  label="Candidates Interviewing With Clients"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="candidates_expecting_offer"
                    />
                  }
                  id="checkbox7"
                  onChange={(e: any) => this.handleCandidateExpectingOfferCkeckBox(e)}
                  className='checkbox_level'
                  label="Candidates Expecting Offers"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="jobs_by_sub_category"
                    />
                  }
                  id="checkbox8"
                  onChange={(e: any) => this.handleJobsBySubCategoryCheckBox(e)}
                  className='checkbox_level'
                  label="Jobs By Sub-Category"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="candidates_accepted"
                    />
                  }
                  id="checkbox9"
                  onChange={(e) => this.handleCandidatesAcceptedCheckBox(e)}
                  className='checkbox_level'
                  label="Candidates Accepted Offers"
                />
              </Box>
              <Box className='inner_checkbox_container'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className='ad_hoc_checkbox'
                      value="waiting_for_client_response"
                    />
                  }
                  id="checkbox10"
                  onChange={(e) => this.handleCandidatesSubmittedAndWaitingForClientCheckBox(e)}
                  className='checkbox_level'
                  label="Candidates Submitted, waiting for client response"
                />
              </Box>
            </Box>

            <Box className='report_period_outer_wrapper'>
              <Box className='report_period_inner_wrapper1'>
                <h4>Report Period : </h4>
                <Box className='radio_btn_outer_wrapper'>
                  <FormControl component="fieldset" className='radio_btn_inner_wrapper'>
                    <RadioGroup aria-label="gender" name="gender1" id='radiobtn' value={this.state.setValue} className='btn_radio_box' onChange={(e: any) => this.handleRadioOnChange(e)}>
                      <FormControlLabel value="weekly" control={<Radio />} label="Weekly" className='radio_button' />
                      <FormControlLabel value="monthly" control={<Radio />} label="Monthly" className='radio_button' />
                      <FormControlLabel value="last_90_days" control={<Radio />} label="last 90 days" className='radio_button' />
                      <FormControlLabel value="custom_date" control={<Radio />} label="Custom date" className='radio_button' />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box className='report_period_inner_wrapper2'>
                <h4>Choose date : </h4>
                <Box className='viewreport_btn_container'>
                  <Box className="flat_picker_container">
                    <input
                      type="text"
                      id="dateRangePicker"
                      autoComplete="off"
                      className="datepicker"
                      placeholder="Select Start and End Date"
                      onClick={this.handleDateOnChange}
                    />
                    <img src={dateIcon} width={13} style={{ marginRight: "15px" }} />
                  </Box>
                  <Box className='btn_generate_report' onClick={this.handleAdHocReportingProgressGetApi} data-testid="generateReportbtn">
                    Generate report
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>


          <Box className='analytics_data_wrapper'>
            <Box className='data_graph_outer_wrapper'>
              <Box className='current_job_opening_container'>
                <h3>{this.state.graphHeading}</h3>
                <h5 onClick={this.print}>Download Report <img src={download} /></h5>
                <Box className='btn_generate_report1' onClick={this.handleOpenGraph}>
                  {
                    this.state.isGraph ? "View graph mode" : "View Data mode"
                  }

                </Box>
              </Box>
              <Box style={{ marginTop: "50px", position: "relative" }}>
                {
                  this.state.isGraph ? "" :
                    <Box>
                      <img src={view_Growth} alt='image' style={{ position: "absolute", right: "10px" }} />
                      <img src={view_Positive} alt='image' style={{ position: "absolute", top: "6px", right: "14px" }} />
                    </Box>
                }

                {
                  this.state.isGraph ?
                    <Box className='datagrid-paper'>
                      <DataGrid
                        rows={paginatedRows}
                        columns={columns}
                        checkboxSelection={false}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableColumnSelector={true}
                        hideFooterSelectedRowCount={true}
                        pageSize={5}
                        className="adHoc_data_table data_drid"
                        components={{
                          Pagination: () => (
                            <Pagination
                              count={this.state.pageCount}
                              page={this.state.setPage}
                              onChange={this.handlePageChange}
                              variant="outlined"
                              shape="rounded"
                              className="mui_custom_pagination"
                            />
                          ),
                        }}
                      />
                    </Box>
                    :
                    <ResponsiveContainer width="100%" height={400}>
                      <AreaChart
                        width={500}
                        height={400}
                        data={this.state?.AdHocGraphData}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 20,
                          bottom: 0,
                        }}
                        id="ppin"
                      >
                        <XAxis
                          dataKey="month"
                          tick={{ fontSize: 14 }}
                          axisLine={false}
                          tickLine={false}
                        />
                        <Tooltip cursor={false} />
                        <Area type="monotone" dataKey="yAxis" stroke="lightGray" fill="#EFEFEF" />
                      </AreaChart>
                    </ResponsiveContainer>
                }

              </Box>
            </Box>
            <Box className='progressBar_outer_wrapper'>
              <Box className='current_job_opening_container'>
                <h3>Reports</h3>
              </Box>
              {this.state.AdHocProgressData?.graph_data?.map((item: any, index: any) => {
               let newStr = item.name.replaceAll("_"," ")
                newStr = this.capitalizeFirstLetter(newStr);
                newStr =  newStr == "Clearance level Public Trust" ? "Clearance level" : newStr ;
                return (
                  <Box className='progressbar-wrapper' key={item} onClick={() => this.handleAdHocReportingGraphGetApi(item.name)} data-testid="generateGraphdata">
                    <Box className='adhoc_report_heading'>
                      <label>{newStr}</label>
                      <label>{item.percentage}</label>
                    </Box>
                    <LinearProgress variant="determinate" value={item.percentage} className={`progressbar_div item_${index + 1}`} />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}
