export const userProfile = require("../assets/user-profile.png");
export const clients = require("../assets/clients.png");
export const candidate = require("../assets/candidates.png");
export const appointment = require("../assets/appointment.png");
export const data = require("../assets/data.png");
export const report = require("../assets/report.png");
export const feeSchedule = require("../assets/feeSchedule.png");
export const logout = require("../assets/logout.png");
export const dashboard = require("../assets/dashboard.png");
export const clients_p = require("../assets/client_p.png");
export const candidate_p = require("../assets/candidate_p.png");
export const appointment_p = require("../assets/appointment_p.png");
export const data_p = require("../assets/data_p.png");
export const report_p = require("../assets/report_p.png");
export const feeSchedule_p = require("../assets/feeschedule_p.png");
export const logout_p = require("../assets/logout_p.png");
export const dashboard_p = require("../assets/dashboard_p.png");