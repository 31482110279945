import React from "react";

import {
  // Customizable Area Start
  Tab,
  Box,
  Divider,
  OutlinedInput,
  InputAdornment,
  Grid,
  FormControl,
  Button,
  CardContent,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel
  // Customizable Area End
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import { NavLink } from "react-router-dom";
// Customizable Area Start
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Footer } from "../../../components/src/Footer.web";
import { Formik } from "formik";
import {FormatDate} from "../../../components/src/FormatDate.web"
const theme = createTheme({
  palette: {
    primary: {
      main: "#ebe534",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import CvresumeCandidateManagement2Controller, {
  Props,
} from "./CvresumeCandidateManagement2Controller.web";
import HeaderWeb from "../../../components/src/Header.web";

export default class CvresumeCandidateManagement2 extends CvresumeCandidateManagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { contactedCandidateList } = this.state;
    return (
      // Customizable Area Start
      <>
        <CvResumeComponent
          state={this.state}
          handleChangeTab={this.handleChangeTab}
          contactedCandidateList={contactedCandidateList}
          handleClick={this.handleClick}
          filterContactedCandidates={this.filterContactedCandidates}
          handleSearch={this.handleSearch}
          getAllActiveCandidateDetails={this.getAllActiveCandidateDetails}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
/* istanbul ignore next */
function CvResumeComponent(props: any) {
  return (
    <>
      <HeaderWeb />
      <ThemeProvider theme={theme}>
        <Formik
          initialValues={props.state.dataTab}
          // validationSchema={}
          onSubmit={(values) => { }}

        >
          {({ errors, values, touched, handleSubmit, handleChange, handleBlur }) => (

            <TabContext value={props.state.newValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "45px" }}>
                <CandidateTab
                  onChange={props.handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab style={{ fontWeight: "bolder", textTransform: "capitalize", fontSize: '18px', marginRight: 40 }} label="Active Candidates" value="1" />
                  <Tab style={{ fontWeight: "bolder", textTransform: "capitalize", fontSize: '18px', marginRight: 40 }} label="Contacted Candidates" value="2" />
                </CandidateTab>
                <Divider style={{ width: "30%" }} />
              </Box>
              <TabPanel value="1" style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <Grid item sm={8}>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        style={webStyle.search}
                        placeholder="Search Active Candidates"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        value={props.state.search}
                        onChange={(event) => props.handleSearch(event)}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{
                                color: "black"
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      variant="outlined"
                      type="submit"
                      style={{
                        background: "white",
                        textTransform: "capitalize",
                        borderRadius: "33px",
                        width: "100%",
                        height: "50px",
                      }}
                    >
                      Show filters
                    </Button>
                  </Grid>
                  <Grid item sm={2}>
                    <NavLink to="/AddCandidateBlockWeb">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{
                          background: "black",
                          width: "100%",
                          color: "white",
                          borderRadius: "33px",
                          height: "50px",
                          textTransform: "capitalize"
                        }}
                      >
                        Add New Candidate
                      </Button>
                    </NavLink>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 50, marginTop: 30 }}>
                  {props.state.activeCandidateList && props.state.activeCandidateList.map((item: any, index: any) => (
                    <Grid key={index} item sm={12} md={6} lg={6}>
                      <Box sx={webStyle.boxContainer}>
                        <CardContent>
                          <Typography gutterBottom style={{ fontWeight: 600, fontSize: '16px' }}>
                            {item.candidate_name}
                          </Typography>
                          <Typography style={{ position: 'absolute', right: 20, top: 20 }} variant="h5" component="div">
                            <Chip icon={<div style={webStyle.chip}></div>
                            } style={{ minWidth: 140, backgroundColor: '#7c7bff' }} label="Interviewing" color="primary" />
                          </Typography>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 18, marginTop: 10 }}>
                            <WorkOutlineOutlinedIcon />
                            <Typography style={{ marginRight: 10, marginLeft: 10 }} >
                              {item.position}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', marginBottom: 18 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <DomainOutlinedIcon />
                              <Typography style={{ marginRight: 10, marginLeft: 10 }} variant="body2">
                                {item.company_name}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <RoomOutlinedIcon />
                              <Typography style={{ marginRight: 10, marginLeft: 10 }} variant="body2">
                                {item.city}, {item.state}
                              </Typography>
                            </div>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                              <CalendarTodayOutlinedIcon />
                              <Typography style={{ marginRight: 10, marginLeft: 10 }} variant="body2">
                                Submission Dates : {FormatDate(item.submission_date)}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                              <CalendarTodayOutlinedIcon />
                              <Typography style={{ marginRight: 10, marginLeft: 10 }} variant="body2">
                                Interview Dates :{FormatDate(item.interview_date)}
                              </Typography>
                            </div>
                          </div>
                          <div style={webStyle.bottomBar}></div>
                          <div style={webStyle.cardFooter}>
                            <span style={{ fontWeight: 400, fontFamily: "Roboto" }}>Recruiter : {item.recruiter}</span>
                            <Button
                              data-test-id="btn_schedule"
                              onClick={props.handleClick}
                              variant="contained"
                              disabled
                              type="submit"
                              style={{
                                textTransform: "capitalize",
                                cursor: "pointer"
                              }}
                            >
                              Fee Schedule
                            </Button>
                          </div>
                        </CardContent>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value="2" style={{ marginTop: "30px" }}>
                <Grid container spacing={3}>
                  <div style={{ marginBottom: 20, marginLeft: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.state.checkboxValues.all}
                          onChange={() => props.filterContactedCandidates("all")}
                          name="all"
                          color="primary"
                          data-test-id="all"
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.state.checkboxValues.contacted}
                          onChange={() => props.filterContactedCandidates("contacted")}
                          name="contacted"
                          color="primary"
                        />
                      }
                      label="Contacted"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.state.checkboxValues.submitted}
                          onChange={() => props.filterContactedCandidates("submitted")}
                          name="submitted"
                          color="primary"
                        />
                      }
                      label="Submitted"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.state.checkboxValues.interested}
                          onChange={() => props.filterContactedCandidates("interested")}
                          name="interested"
                          color="primary"
                        />
                      }
                      label="Interested"
                    />
                  </div>
                </Grid>
                <Grid container spacing={3} style={{ marginBottom: 50, marginTop: 30 }}>
                  {props.contactedCandidateList.map((item: any, index: any) => (
                    <Grid key={index} item sm={12} md={6} lg={6}>
                      <Box sx={webStyle.boxContainer}>
                        <CardContent>
                          <Typography gutterBottom style={{ fontWeight: "bold", fontSize: '16px' }}>
                            {item.candidate_name}
                          </Typography>
                          <Typography style={{ position: 'absolute', right: 40, top: 20 }} variant="h6" component="div">
                            {item.salary}
                          </Typography>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 18, marginTop: 10 }}>
                            <WorkOutlineOutlinedIcon />
                            <Typography style={{ marginRight: 10, marginLeft: 10 }} >
                              {item.position}
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', marginBottom: 18 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <DomainOutlinedIcon />
                              <Typography style={{ marginRight: 10, marginLeft: 10 }} variant="body2">
                                {item.company_name}
                              </Typography>
                            </div>
                          </div>
                          <div style={webStyle.bottomBar}></div>

                          <Grid container spacing={3}>
                            <Grid key={1} item sm={4} md={4} lg={4}>
                              <span style={{ fontWeight: 400, fontFamily: "Roboto" }}>Recruiter : {item.recruiter}</span>
                            </Grid>
                            <Grid key={2} item sm={8} md={8} lg={8} style={{ display: "flex", justifyContent: "end", flexWrap: 'wrap' }}>
                              <span style={webStyle.ccFooter}>{`Contacted (${item.contacted ? item.contacted : ''})`}</span>
                              <span style={webStyle.ccFooter}>{`Submitted (${item.submitted ? item.submitted : ''})`}</span>
                              <span style={webStyle.ccFooter}>{`Interested (${item.intrested ? item.intrested : ''})`}</span>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
            </TabContext>
          )}
        </Formik>
      </ThemeProvider>
      <Footer />
    </>
  )
}
const webStyle = {
  boxContainer: {
    position: 'relative',
    border: '1px solid #e3e3e3',
    borderRadius: 8,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  },
  search: {
    borderRadius: "33px",
    borderColor: "#979797",
    height: "50px",
    "& .MuiInputBase-input": {
      color: "green"
    }
  },
  chip: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'white',
    marginRight: 1
  },
  bottomBar: {
    border: '2px solid #e3e3e3',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ccFooter: {
    fontWeight: 400,
    fontFamily: "Roboto",
    marginRight: 20
  }
};
const CandidateTab = withStyles({
  root: {
    "& .MuiTabs-scroller": {
      paddingLeft: "20px",
    },
    "& .PrivateTabIndicator-root-5": {
      backgroundColor: "black",
      height: "7px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "black",
      height: "7px",
    }
  }

})(TabList);

// Customizable Area End
