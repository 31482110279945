import React from "react";

// Customizable Area Start
import {
  Box,
  Typography
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { logo } from "./assets";
import './EmailAccount.css'


import EmailAccountActivationController,{Props} from "./EmailAccountActivationController";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

  

  export default class EmailAccountActivation extends  EmailAccountActivationController{
    constructor(props:Props){
        super(props)
    }

    render(){
        return(
        <Box style={{
          margin:'0 rem',
          padding:'0 rem',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          height:'100vh'
        }}>
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width:'24rem',
              bgcolor:'black',
              color:'white',
              alignItems: "center",
              borderRadius:'0.5rem',
              padding:'1.5rem 1rem',
              fontFamily: 'Lato-Regular',
            }}
          > 
            <Box style={{width:'100%',}}>
              <img style={{width:'100%', height:'100px',objectFit:'cover'}} src={logo}/>
            </Box>
           
           {this.state.loading && <Typography className="fontStyle">
                Activating your account...
           </Typography>}
           {!this.state.loading && <Typography className="fontStyle">
                Account activated successfully.
           </Typography>}
          </Box>
        </Box>
        )
    }

  }

// Customizable Area End
