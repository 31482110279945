import React from 'react'
import { DataGrid } from "@material-ui/data-grid";
import { Avatar, Box } from "@material-ui/core";
import { filterIcon, closeIcon } from "../../../blocks/advancedsearch/src/assets";
import AdvancedSearchControllerWeb, { Props } from '../../../blocks/advancedsearch/src/AdvancedSearchController.web';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';


const columns = [
    {
        field: "id",
        headerName: "candidate id",
        width: 200,
        editable: false,
        sortable: false
    },
    {
        field: "candidate",
        headerName: "candidate",
        width: 200,
        editable: false,
        sortable: false,
        renderCell: (e: any) => {
            return (
                <div className='candidate_profile_column_wrapper'>
                    <Avatar src={""} className='candidate_profile_column_image' />
                    <span>{e.formattedValue}</span>
                </div>
            )
        }
    },
    {
        field: "Assigned_to",
        headerName: "Assigned to",
        width: 150,
        editable: false,
        sortable: false
    },
    {
        field: "Job_type",
        headerName: "Job Type",
        width: 150,
        editable: false,
        sortable: false
    },
    {
        field: "clearance",
        headerName: "Clearance",
        width: 150,
        editable: false,
        sortable: false
    },
    {
        field: "Admin",
        headerName: "Assigned Admin",
        width: 150,
        editable: false,
        sortable: false
    },
];


export default class FinalSelectionList extends AdvancedSearchControllerWeb {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const finalListWithIds = this.props.finalList?.map((row: any, index: number) => ({
            id: index + 1,
            ...row
        }));
        return (
            <div style={{ padding: "0 40px" }}>
                <Snackbar
                    open={this.state.openNotification}
                    autoHideDuration={3000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    onClose={this.handleCloseNotification}
                >
                    <Alert onClose={this.handleCloseNotification} severity="success" variant="filled">
                        Records created and submitted successfully
                    </Alert>
                </Snackbar>
                <Box display={"flex"} justifyContent={"space-between"} height={"100px"} alignItems={"center"}>
                    <Box fontSize={"20px"} fontWeight={"500"}>Assigned Candidates</Box>
                    <Box>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Box mr={"40px"} display={"flex"} justifyContent={"space-between"} width={"90px"} alignItems={"center"}>
                                <img src={filterIcon} alt='sortby' width={18} />
                                <span className='filter-txt'>Sort by</span>
                            </Box>
                            <Box className='cancel-btn' onClick={() => this.props.dialogAction()}>
                                <img src={closeIcon} alt='image' width={15} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='datagrid-paper'>
                    <DataGrid
                        rows={finalListWithIds}
                        columns={columns}
                        pageSize={7}
                        checkboxSelection={false}
                        disableSelectionOnClick
                    />
                </Box>
                <Box className='btn_div_wrapper'>
                    <Box
                        className='button-common-div modify_search'
                        onClick={() => this.props.dialogAction("edit")}
                    >
                        Edit
                    </Box>
                    <Box className='button-common-div new_search'
                        onClick={() => this.postConfirmList(this.props.dataArray)}
                    >
                        Confirm
                    </Box>
                </Box>
            </div>
        )
    }
}