import moment from "moment";

export const FormatDate = (date: any) => {
    const regexFormats = [
        'DD/MM/YYYY',
        'MM/DD/YYYY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
        'YYYY/MM/DD',
        'MM-DD-YYYY',
      ];
  
      let formattedDate = moment(date, regexFormats, true).format('MM/DD/YYYY');
  
      if (!moment(formattedDate, 'MM/DD/YYYY', true).isValid()) {
        formattedDate = date;
      }
  
      return formattedDate;
}

