import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Button
} from '@material-ui/core';

interface Column {
  header: string;
  dataKey: string;
}

interface CustomDataTableProps {
  data: any[];
  columns: Column[];
  rowsPerPageOptions: number[];
  isExpanded?: boolean;
  expandedData?: any[];
  checkboxSelection?: boolean;
  onSelect?: (selectedData: any[]) => void;
  selectedData?:any;
  resetPagination?:any;
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({ data, columns, rowsPerPageOptions, isExpanded, expandedData, checkboxSelection, onSelect, selectedData, resetPagination }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]);
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  const [selectedRows, setSelectedRows] = useState<string[]>([]); // Changed to store IDs instead of indices
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [activePage, setActivePage] = useState(1);


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleRowClick = (rowIndex: number) => {
    if (isExpanded) {
      const updatedExpandedRows = new Set(expandedRows);
      if (expandedRows.has(rowIndex)) {
        updatedExpandedRows.delete(rowIndex);
      } else {
        updatedExpandedRows.add(rowIndex);
      }
      setExpandedRows(updatedExpandedRows);
    }
  };

  const handleRowSelect = (rowIndex: number, id: string) => {
    const selectedRowSet = new Set(selectedRows);
    if (selectedRowSet.has(id)) {
      selectedRowSet.delete(id);
    } else {
      selectedRowSet.add(id);
    }
    setSelectedRows(Array.from(selectedRowSet));
    onSelect && onSelect(selectedRows);
  };

  const handleSelectAll = () => {
    if (!selectAllChecked) {
      setSelectedRows(data.map((row) => row.Id)); 
    } else {
      setSelectedRows([]);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const isRowExpanded = (rowIndex: number) => {
    return expandedRows.has(rowIndex);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  const totalPages = Math.ceil(data.length / rowsPerPage);

  useEffect(() => {
    updateVisiblePages();
  }, [page, rowsPerPage, data]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedRows);
    }
  }, [selectedRows, onSelect]);

  useEffect(() => {
    selectedData.length > 0 && setSelectedRows(selectedData);
  }, []);

  useEffect(() => {
    handleChangePage(null, resetPagination);
    console.log("@@_resetPagination", resetPagination, page);
  }, [data])

  const updateVisiblePages = () => {
    if (totalPages <= 5) {
      setVisiblePages(Array.from({ length: totalPages }, (_, index) => index + 1));
    } else if (page < 3) {
      setVisiblePages([1, 2, 3, 4, 5]);
    } else if (page >= totalPages - 3) {
      setVisiblePages(Array.from({ length: 5 }, (_, index) => totalPages - 4 + index));
    } else {
      setVisiblePages(Array.from({ length: 5 }, (_, index) => page - 2 + index));
    }
    setActivePage(page + 1);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell style={{ width: "50px" }}>
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell key={index}>{column.header}</TableCell>
              ))}
              {isExpanded && expandedData && expandedData.length > 0 && (
                <TableCell/>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <React.Fragment key={index}>
                <TableRow className={selectedRows.includes(row.Id) ? "active" : `${row.Id}`}>
                  {checkboxSelection && (
                    <TableCell style={{ width:"50px"}} className={isRowExpanded(index) ? "expanded" : ""}>
                      <Checkbox
                        checked={selectedRows.includes(row.Id)}
                        onChange={() => handleRowSelect(index, row.Id)}
                      />
                    </TableCell>
                  )}
                  {columns.map((column, columnIndex) => (
                    <TableCell 
                      key={columnIndex} 
                      className={isRowExpanded(index) ? "expanded" : ""} 
                      onClick={() => handleRowClick(index)}
                    >
                      {row[column.dataKey] ? row[column.dataKey] : "-"}
                    </TableCell>
                  ))}
                  {isExpanded && expandedData && expandedData.length > 0 && (
                    <TableCell className={isRowExpanded(index) ? "expanded" : ""}>
                      <span
                        onClick={() => handleRowClick(index)}
                      >
                        {isRowExpanded(index) ? 
                          <span style={{display:"inline-block",boxSizing:"border-box",border:"4px solid",borderColor:"#000 #000 transparent transparent",transform:"rotate(-45deg)"}}></span> : 
                          <span style={{display:"inline-block",boxSizing:"border-box",border:"4px solid",borderColor:"transparent transparent #000 #000",transform:"rotate(-45deg)"}}></span>
                        }                        
                      </span>
                    </TableCell>
                  )}
                </TableRow>
                {isExpanded && isRowExpanded(index) && expandedData && expandedData.length > 0 && (
                  <TableRow className='expanded-row'>
                    <TableCell colSpan={checkboxSelection && isExpanded ? columns.length + 2 : columns.length}>
                      {expandedData[index].map((item: any, itemIndex: number) => (
                        <div key={itemIndex} className='expanded-data'>
                          {Object.entries(item).map(([key, value]: any) => (
                            <span key={key}>{key}: {value}</span>
                          ))}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={checkboxSelection ? columns.length + 1 : columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='tblTwo-footer'>
        <TablePagination
          className='tbl-pagination'
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} from ${count}`}
          labelRowsPerPage=""
        />

        {totalPages > 1 && (
          <div id="pageNumberBlock" className='pagelist-block'>
            {visiblePages.map((pageNumber) => (
              <Button
                key={pageNumber}
                className={`page-btn ${pageNumber === activePage ? 'active' : ''}`}
                onClick={() => handleChangePage(null, pageNumber - 1)}
              >
                {pageNumber}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDataTable;