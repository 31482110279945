Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Terms And Conditions";
exports.labelBodyText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultrices vitae auctor eu augue. In est ante in nibh mauris cursus mattis. Proin libero nunc consequat interdum varius sit amet mattis vulputate. Odio ut enim blandit volutpat maecenas volutpat blandit. Erat nam at lectus urna duis convallis convallis. Ipsum consequat nisl vel pretium. Vitae purus faucibus ornare suspendisse sed nisi lacus sed. Neque egestas congue quisque egestas diam in arcu cursus euismod. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Nulla facilisi nullam vehicula ipsum a. Sed vulputate odio ut enim. Ultricies integer quis auctor elit sed vulputate mi sit.";

exports.btnExampleTitle = "Continue";
// Customizable Area End