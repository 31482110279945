import React, { Component } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const data = [
    {
        name: "Feb",
        uv: 3000,

    },
    {
        name: "Mar",
        uv: 2000,

    },
    {
        name: "Jan",
        uv: 6000,

    },

    {
        name: "Apr",
        uv: 2780,

    },

    {
        name: "Jul",
        uv: 3490,

    },
    {
        name: "Aug",
        uv: 3490,

    },
    {
        name: "Sep",
        uv: 3490,

    },
    {
        name: "Oct",
        uv: 3490,

    },
    {
        name: "Nov",
        uv: 3490,

    },
    {
        name: "Dec",
        uv: 3490,

    },
    {
        name: "May",
        uv: 1890,

    },
    {
        name: "Jun",
        uv: 2390,

    }
];

export default class Graph extends Component {
    render() {
        return (
            <ResponsiveContainer width="100%" height={256}>
                <AreaChart
                    width={500}
                    height={256}
                    data={data}
                    margin={{
                        // top: 10,
                        // right: 30,
                        left: 20,
                        bottom: 0,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="1 1" /> */}
                    <XAxis
                        dataKey="name"
                        // height={200}
                        tick={{ fontSize: 14 }}
                        axisLine={false}
                        tickLine={false} />
                    {/* <YAxis /> */}
                    <Tooltip cursor={false} />
                    <Area type="monotone" dataKey="uv" stroke="lightGray" fill="#EEEEEE" />
                </AreaChart>
            </ResponsiveContainer>
        );
    }
}
