import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo } from "./assets";
import './EmailAccount.css'
import { Formik  } from 'formik';
import * as Yup from 'yup';

import EmailAccountRegistrationController,{Props} from "./EmailAccountRegistrationController";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

  

  export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props:Props){
        super(props)
    }


    render(){
        return(
        <Box style={{
          margin:'0 rem',
          padding:'0 rem',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems:'center',
          height:'100vh'
        }}>
          
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width:'24rem',
              bgcolor:'black',
              color:'white',
              alignItems: "center",
              borderRadius:'0.5rem',
              padding:'1.5rem',
              fontFamily: 'Lato-Regular',
            }}
          >
            
            <Box style={{width:'100%',}}>
              <img style={{width:'100%', height:'100px',objectFit:'cover'}} src={logo}/>
            </Box>
            {this.state.showRegistrationMessage&& 
            <Box className="fontStyle" style={{marginTop:'1rem',display:'flex',justifyContent:'center', alignItems:'center',padding: "0.5rem 1rem",fontFamily: 'Lato-Regular',}}>
              We have sent you an Email.<br/>Click on the link in email to activate account.
            </Box>
            }
            
            {!this.state.showRegistrationMessage&&<Box sx={{ width: "100%", paddingTop: "0.5rem" }}>
            <Formik
              validateOnChange
              validateOnBlur
              initialValues={{
                fullName: '',
                email: '',
                password: '',
                reTypePassword: '',
              }}
              validationSchema={Yup.object().shape({
                fullName: Yup.string().required('Name is required'),
                email: Yup.string().required('Email is required').matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,'Invalid Email'),
                password: Yup.string().required('Password is required').matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,16}$/,'Password must contain one lowercase , one uppercase , one special character and one digit. Must be 8-16 characters long.'),
                reTypePassword:Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
              })}
        
              onSubmit={(values: any) => 
                {
                  this.createAccount(values)
                }
              }
            >
        {({ handleSubmit, handleChange ,values, touched, errors }:any) => (
          
          <form onSubmit={handleSubmit}>
              
              <Box>
              <Box sx={{ padding: "0.5rem 1rem",}}> 
              <Typography className="fontStyle">Your Name</Typography>
                <Input
                  name="fullName"
                  data-test-id="txtInputName"
                  fullWidth={true}
                  value={values.fullName}
                  onChange={handleChange}
                  disableUnderline
                  inputProps={{ 'aria-label': 'first name' }}
                  error={errors.fullName && touched.fullName}
                  style={{
                    backgroundColor:'white',
                    color:'black',
                    padding:'0.5rem',
                    borderRadius:'0.5rem',
                    border:`${this.state.nameColor} 2px solid`
                  }}
                  onFocus={()=>this.setState({nameColor:'yellow'})}
                  onBlur={()=>this.setState({nameColor:'black'}) 
                              }
                />
                {errors.fullName && touched.fullName&&<p className="fontStyle errorMessage">{errors.fullName}</p>}
              </Box>
              <Box sx={{ padding: "0.5rem 1rem" , }}> 
              <Typography className="fontStyle">Email</Typography>
                <Input
                  style={{
                    backgroundColor:'white',
                    color:'black',
                    padding:'0.5rem',
                    borderRadius:'0.5rem',
                    border:`${this.state.emailColor} 2px solid`
                  }}
                  disableUnderline
                  data-test-id="txtInputEmail"
                  type={"email"}
                  fullWidth={true}
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                  onFocus={()=>this.setState({emailColor:'yellow'})}
                  onBlur={()=>this.setState({emailColor:'black'}) 
                              }
                  error={errors.email && touched.email}
                />
                {errors.email && touched.email&&<p className="fontStyle errorMessage">{errors.email}</p>}
              </Box>

              <Box sx={{ padding: "0.5rem 1rem" ,fontFamily: 'Lato-Regular',}}> 
                <Typography className="fontStyle">Set a Password</Typography>
                <Input
                  style={{
                    backgroundColor:'white',
                    color:'black',
                    padding:'0.5rem',
                    borderRadius:'0.5rem',
                    border:`${this.state.pasColor} 2px solid`
                  }}
                  disableUnderline
                  data-test-id="txtInputPassword"
                  type={"password"}
                  fullWidth={true}
                  name="password"
                  value={values?.password}
                  onChange={handleChange}
                  onFocus={()=>this.setState({pasColor:'yellow'})}
                  onBlur={()=>this.setState({pasColor:'black'}) 
                              }
                  error={errors.password && touched.password}
                />
                  {errors.password && touched.password&&<p className="fontStyle errorMessage" >{errors.password}</p>}
              </Box>

              <Box sx={{ padding: "0.5rem 1rem" ,fontFamily: 'Lato-Regular',}}> 
                  <Typography className="fontStyle">Confirm Password</Typography>
                <Input
                  style={{
                    backgroundColor:'white',
                    color:'black',
                    padding:'0.5rem',
                    borderRadius:'0.5rem',
                    border:`${this.state.conPasColor} 2px solid`
                  }}
                  name="reTypePassword"
                  disableUnderline
                  data-test-id="txtInputPassword"
                  type={"password"}
                  fullWidth={true}
                  value={values?.reTypePassword}
                  onChange={handleChange}
                  onFocus={()=>this.setState({conPasColor:'yellow'})}
                  onBlur={()=>this.setState({conPasColor:'black'})
                              }
                />
                  {errors.reTypePassword && touched.reTypePassword&&<p className="fontStyle errorMessage">{errors.reTypePassword}</p>}
              </Box>
              </Box>
              
              {this.state.erroMessage!==''&&<Box style={{padding:'0.5rem 1rem'}}>
                  <Typography className="fontStyle errorMessage">{this.state.erroMessage}</Typography>
                  </Box>}
            <Box sx={{
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'center',
              padding:'0.5rem',
            }}>
            
                <Button
                  className="fontStyle"
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  fullWidth
                  color='inherit'
                  style={{
                    backgroundColor: 'rgba(199, 0, 57, 1)',
                    color:'white',
                    width:'15rem',
                    textTransform:'none',
                    fontWeight:'normal'
                  }}
                  type="submit"
                >
                  Submit {/*UI Engine::From Sketch*/}
                </Button>
                
            </Box>
            </form>
            )}
            </Formik>        
            </Box>}
          </Box>
        </Box>
        )
    }

  }

// Customizable Area End
