
import React from "react";
import { Box } from "@material-ui/core";
import { HeaderLogo } from '../../components/src/assets.web';
export function Footer() {
    return (
        <Box sx={webStyle.box1}>
            <img src={HeaderLogo} alt=" " style={webStyle.image} />
        </Box>
    )
}


const webStyle = {
    box1: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        backgroundColor: "black",
        width: '100%',
        padding: 10,
        textAlign: 'center'
    },
    image: {
        height: "60px",
        backgroundColor: 'black',
        width: "150px",

    }
}