import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { logo } from "./assets";
import './EmailAccount.css'
import { Formik } from 'formik';
import * as Yup from 'yup';


import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <Box style={{
        margin: '0 rem',
        padding: '0 rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: '24rem',
            minHeight: '30rem',
            bgcolor: 'black',
            color: 'white',
            alignItems: "center",
            borderRadius: '0.5rem',
            padding: '1rem 2rem',
          }}
        >
          <Box style={{ width: '100%', }}>
            <img style={{ width: '100%', height: '100px', objectFit: 'cover' }} src={logo} />
          </Box>


          <Box sx={{ width: "100%", paddingTop: "1rem" }}>
            <Formik
              validateOnChange
              validateOnBlur
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required('Email is required').matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Invalid Email'),
                password: Yup.string().required('Password is required'),
              })}

              onSubmit={(values: any) => {
                this.doEmailLogIn(values)
              }
              }
            >
              {({ handleSubmit, handleChange, values, touched, errors }: any) => (
                <form onSubmit={handleSubmit}>

                  <Box>
                    <Box sx={{ padding: "0.5rem 1rem" }}>
                      <Typography className="fontStyle">Your Email</Typography>
                      <Input
                        data-test-id="txtInputEmail"
                        fullWidth={true}
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        /* istanbul ignore next */
                        onFocus={() => this.setState({ emailColor: 'yellow' })}
                        onBlur={() => this.setState({ emailColor: 'black' })
                        }
                        disableUnderline
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          padding: '0.5rem',
                          borderRadius: '0.5rem',
                          border: `${this.state.emailColor} 2px solid`
                        }}
                      />
                      {errors.email && touched.email && <p className="fontStyle errorMessage">{errors.email}</p>}
                    </Box>
                    <Box sx={{ padding: "0.5rem 1rem" }}>
                      <Typography className="fontStyle">Your Password</Typography>
                      <Input
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          padding: '0.5rem',
                          borderRadius: '0.5rem',
                          border: `${this.state.passColor} 2px solid`
                        }}
                        name="password"
                        disableUnderline
                        /* istanbul ignore next */
                        onFocus={() => this.setState({ passColor: 'yellow' })}
                        onBlur={() => this.setState({ passColor: 'black' })
                        }
                        data-test-id="txtInputPassword"
                        error={errors.password && touched.password}
                        type={this.state.enablePasswordField ? "password" : "text"}
                        fullWidth={true}
                        value={values?.password}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <VisibilityOff style={{ color: 'black' }} />
                              ) : (
                                <Visibility style={{ color: 'black' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && touched.password && <p className="fontStyle errorMessage">{errors.password}</p>}
                    </Box>
                  </Box>
                  {this.state.errorMessage !== '' &&
                    <Box style={{ padding: '0.5rem 1rem' }}>
                      <Typography className="fontStyle errorMessage">{this.state.errorMessage}</Typography>
                    </Box>
                  }
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1rem 0rem'
                  }}>

                    <Button
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      fullWidth
                      type="submit"
                      color='inherit'
                      style={{
                        backgroundColor: 'rgba(199, 0, 57, 1)',
                        color: 'white',
                        width: '15rem',
                        textTransform: 'none',
                        fontWeight: 'normal',
                      }}
                    >
                      Sign In {/*UI Engine::From Sketch*/}
                    </Button>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "0.5rem 0rem",
                      }}
                    >
                    </Box>

                    <Button
                      data-test-id="btnSocialLogin"
                      variant="contained"
                      color='inherit'
                      fullWidth

                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        width: '15rem',
                        textTransform: 'none',
                        fontWeight: 'normal',
                      }}
                    >
                      {this.state.btnTxtSocialLogin} {/*UI Engine::From Sketch*/}
                    </Button>

                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: '22rem',
          justifyContent: 'space-between',
          padding: "0.5rem ",
        }}>

          <Typography
            data-test-id={"btnForgotPassword"}
            style={{
              fontSize: '0.75rem',
              color: 'rgba(0, 0, 0, 1)',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={this.goToRegistrationPage}
          > Don't have an account ?
          </Typography>
          <Typography
            style={{
              fontSize: "0.75rem",
              color: 'rgba(0, 0, 0, 1)',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => this.goToForgotPassword()}
          >
            Forgot Password ?
          </Typography>
        </Box>
      </Box>
    );
  }
}

// Customizable Area End
