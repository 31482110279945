import React, { Component } from 'react';
import {
  Typography,
  Grid,
  MenuItem,
  ClickAwayListener,
  Menu,
  Popper,
  Grow,
  Paper,
  MenuList,
  Divider,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { HeaderLogo, DummyProfile } from "./assets.web";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import { clients, candidate, appointment, data, report, feeSchedule, logout, dashboard, clients_p, candidate_p, appointment_p, data_p, report_p, feeSchedule_p, dashboard_p, logout_p } from '../../blocks/navigationmenu/src/assets';



type MyProps = RouteComponentProps<any> & {};
interface MyState { menu?: any, profileMenu?: any, getUserProfileData?: any, selectedProfileImage: any, userDetails: any, setOpen: boolean, imgData: number };


const Data = [
  {
    id: 0,
    title: "Clients",
    img_one: clients,
    img_two: clients_p,
  },
  {
    id: 1,
    title: "Candidates",
    img_one: candidate,
    img_two: candidate_p,
  },
  {
    id: 2,
    title: "Appointment",
    img_one: appointment,
    img_two: appointment_p,
  },
  {
    id: 3,
    title: "Data",
    img_one: data,
    img_two: data_p,
  },
  {
    id: 4,
    title: "Dashboard",
    img_one: dashboard,
    img_two: dashboard_p,
  },
  {
    id: 5,
    title: "Reports",
    img_one: report,
    img_two: report_p,
  },
  {
    id: 6,
    title: "Feeschedule",
    img_one: feeSchedule,
    img_two: feeSchedule_p,
  },
  {
    id: 7,
    title: "Logout",
    img_one: logout,
    img_two: logout_p,
  },
]

export class HeaderWeb extends Component<MyProps, MyState> {
  [x: string]: any;
  constructor(props: MyProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick1 = this.handleClick1.bind(this);
    this.handleClose1 = this.handleClose1.bind(this);
    this.state = {
      menu: false,
      profileMenu: false,
      selectedProfileImage: null,
      userDetails: "",
      setOpen: false,
      imgData: NaN
    }
  }
  handleClick(e: any) {
    this.setState({ menu: e.currentTarget });
  }
  handleClose(e: any) {
    this.setState({ menu: null });
  }
  handleClick1(e: any) {
    this.setState({ profileMenu: e.currentTarget });
  }
  handleClose1(e: any) {
    this.setState({ profileMenu: null });
  }
  // Customizable Area start
  handleDrawerOpen = () => {
    this.setState({ setOpen: true });
  };
  // Customizable Area End

  // Customizable Area start
  handleDrawerClose = () => {
    this.setState({ setOpen: false });
  };
  // Customizable Area End

  // Customizable Area start
  handleChange = (e: any) => {
    this.setState({ imgData: e })
  }
  // Customizable Area End

  // Customizable Area start
  handleLeaveChange = () => {
    this.setState({ imgData: NaN })
  }
  // Customizable Area End
  async componentDidMount() {
    this.getProfileData();
  }
  

  getProfileData() {
    const token: any = localStorage.getItem('token')
    fetch(
      `https://recruitingbackgroundclearances-287872-ruby.b287872.dev.eastus.az.svc.builder.cafe/account_block/get_profile`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        token: token
      },
    }
    )
      .then(response => response.json())
      .then(json => {
        if (json && json.errors && json?.errors[0]?.token === "Invalid token" || json && json.errors && json?.errors[0]?.token === "Token has Expired") {
          this.props.history.push('/');
          localStorage.clear();
        }
        this.setState({ selectedProfileImage: json?.data?.attributes?.photo })
      })
      .catch(() => {
        console.log("ERROR GETTING DATA FROM BE");
      });
  }
  render() {
    return (
      <>
        <Grid container style={{ background: "black", justifyContent: "center", position: "sticky", top: "0", zIndex: 999 }}>
          <Grid item xs={12} sm={12} md={12} style={{ alignItems: "center" }}>
            <Grid container style={{
              height: "120px",
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'black',
              padding: '0px 20px',
              color: 'white',
            }}>
              <Grid item xs={10} sm={9} md={4} lg={5} style={{ alignItems: "center" }}>
                <MenuButton onClick={this.handleClick} style={{ color: "white", width: "50px", height: "50px", }}>
                  <MenuIcon />
                </MenuButton>
                <Menu
                  id="fade-menu"
                  anchorEl={this.state.menu}
                  keepMounted
                  open={Boolean(this.state.menu)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>
                    <Typography>Clients</Typography>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    <Typography>Candidates</Typography>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    <Typography>Appointment</Typography>
                  </MenuItem>
                  <MenuItem onClick={this.handleClose}>
                    <Typography>Data</Typography>
                  </MenuItem>
                </Menu>
                <img style={{ width: "326px", height: "86px", cursor: 'pointer' }} src={HeaderLogo} alt="HeaderLogo" onClick={() => { window.location.href = "/WithoutFeeSchedule" }} />
              </Grid>
              <Grid item md={6} lg={5} style={webStyle.menuGrid}
              >
                <MenuGrid style={{ margin: '0rem 1rem', color: "#E5E5EA", textTransform: "uppercase" }}>Clients</MenuGrid>
                <MenuGrid style={{ margin: '0rem 1rem', color: "#E5E5EA", textTransform: "uppercase" }}>Candidates</MenuGrid>
                <MenuGrid style={{ margin: '0rem 1rem', color: "#E5E5EA", textTransform: "uppercase" }}>Appointment</MenuGrid>
                <MenuGrid style={{ margin: '0rem 1rem', color: "#E5E5EA", textTransform: "uppercase" }}>Data</MenuGrid>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: "center",
                }}>
                <Button onClick={this.handleClick1}>
                  <div style={{ width: '45px', borderRadius: '50%', height: '45px' }}>
                    <img className='profileImage' src={this.state.selectedProfileImage || DummyProfile} alt='UserProfile' style={{ width: '45px', borderRadius: '50%', height: '45px' }} />
                  </div>
                  <ExpandMoreIcon style={{ color: "white" }} />
                </Button>
                <Grid container style={webStyle.headerMenu}>
                  <Popper open={Boolean(this.state.profileMenu)} anchorEl={this.state.profileMenu} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose1}>
                            <MenuList id="menu-list-grow" style={{maxHeight:"500px",overflowX:"auto"}}>
                              <NavLink to="/UserProfileBasicBlockWeb" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>My Profile</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/AdHoc" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Ad-Hoc Reporting</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/AdminConsole" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Admin Console</MenuItem>
                              </NavLink>
                              <Divider />
                              <MenuItem>Settings</MenuItem>
                              <Divider />
                              <NavLink to="/PostCreationBasicBlockWeb" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Post Creation</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/FeeSchedules" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Fee Schedules</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/CurrentJobOpeningBlock" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Current Job Opening</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/ImportExportData" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Data Import Export</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/CvresumeCandidateManagement2web" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Active Candidate</MenuItem>
                              </NavLink>
                              <NavLink to="/Dashboard" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Dashboard With Fee Schedule</MenuItem>
                              </NavLink>
                              {/* <NavLink to="/WithoutFeeSchedule" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Dashboard without fee schedule</MenuItem>
                              </NavLink> */}
                              <NavLink to="/AdvancedSearch" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Advanced Search</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/CandidateSearch" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Filter Items</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/Prioritise" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Prioritise</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/MailingListIntegration" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Mailing List Integration </MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/OutlookIntegrationNew" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Outlook Integration</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/dataEncryptionMain" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem >Data Encryption</MenuItem>
                              </NavLink>
                              <Divider />
                              <NavLink to="/accountScoreRankingmain" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem>Account Score Ranking</MenuItem>
                              </NavLink>

                              <Divider />
                              <NavLink to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <MenuItem onClick={() => localStorage.clear()}>Log out</MenuItem>
                              </NavLink>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="drawer_wrapper_div">
            <AppBar
              position="fixed"
            >
              <div
                color="inherit"
                onClick={this.state.setOpen ? this.handleDrawerClose : this.handleDrawerOpen}
                id='hamburger_icon'
                style={{ cursor: "pointer" }}
              >
                {this.state.setOpen ? <CloseIcon /> : <MenuIcon />}
              </div>
            </AppBar>

            <Drawer
              className="drawer_custom_class"
              variant="persistent"
              anchor="right"
              open={this.state.setOpen}
            >
              <List className='list-item-wrapper'>
                {
                  Data.map((event, index) => {
                    return (
                      <div className="nav-bar-outer-div">
                        <ListItem
                          button
                          className='listitem_div'
                          onMouseEnter={() => this.handleChange(index)}
                          onMouseLeave={this.handleLeaveChange}
                          onClick={() => {
                            if (event.title == "Logout") {
                              localStorage.clear();
                              this.props.history.push("/")
                            }
                          }
                          }
                        >
                          <ListItemText primary={event.title} className='text_item_div' />
                          <img src={this.state.imgData == index ? event?.img_two : event?.img_one} alt='image' height={30} />
                        </ListItem>
                        {
                          event.title == "Feeschedule" ? <div style={{ fontSize: "10px", display: "flex", justifyContent: "center", textAlign: "end" }}>You do not have access privileges <br /> to the fee schdule page</div> : ""
                        }
                      </div>
                    )
                  })
                }
              </List>
            </Drawer>
          </div>
        </Grid>
      </>
    );
  }
}
// Customizable Area Start
const webStyle: any = {
  menuGrid: {
    display: "flex",
    flexDirection: "row",
  },
  headerMenu: {
    zIndex: "1",
  },

}
const MenuButton: any = withStyles({
  root: {
    " & .MuiSvgIcon-root": {
      display: "inline-block",
    },
    "@media (min-width: 960px)": {
      display: "none",
    },

  }
})(MenuIcon);
const MenuGrid: any = withStyles({
  root: {
    " .MuiTypography-root": {
      display: "flex",
    },
    "@media (max-width: 960px)": {
      display: "none",
    },

  }
})(Typography);
export default withRouter(HeaderWeb);
  // Customizable Area End