import React from "react";
import {
  Box,
  InputBase,
  Button,
  Typography,
  // Customizable Area Start
  TextField,
  MenuItem,
  Grid,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area 
import { createTheme, withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
/* istanbul ignore next */
import { HeaderLogo } from "../../../components/src/assets.web"
import moment from "moment";
const medium = [
  {
    value: 'Indeed',
    label: 'Indeed',
  },
  {
    value: 'Linkedin',
    label: 'Linkedin',
  },
  {
    value: 'Clearance',
    label: 'Clearance',
  }
];

const position = [
  {
    value: 'Software Engineer',
  },
  {
    value: 'Mechanical Engineer',
  },
  {
    value: 'Chemical Engineer',
  },
];

const state = [
  {
    value: 'gujarat',
  },
  {
    value: 'Rajsthan',
  },
  {
    value: 'Bihar',
  },
  {
    value: 'Maharastra',
  },
];

const city = [
  {
    value: 'Morbi',
  },
  {
    value: 'Rajkot',
  },
  {
    value: 'Surat',
  },
  {
    value: 'Ahemdabad',
  },
];

import HeaderWeb from "../../../components/src/Header.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CvresumeCandidateManagement2Controller, {
  Props,

} from "./CvresumeCandidateManagement2Controller";
import { Footer } from "../../../components/src/Footer.web";

 

export default class FeeSchedules extends CvresumeCandidateManagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
   formatNewDate = (date: any) => {
    const regexFormats = [
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'YYYY/MM/DD',
      'MM-DD-YYYY',
    ];
  
    let formattedDate = moment(date, regexFormats, true).format('MM/DD/YYYY');
  
    if (!moment(formattedDate, 'MM/DD/YYYY', true).isValid()) {
      formattedDate = date;
    }
  
    return formattedDate;
  };
  handleDateChange = (e:any) => {
    const selectedDate = e.target.value;
    const formattedDate = this.formatNewDate(selectedDate);
    this.setState({ date: selectedDate, formattedDate });
  };
  // Customizable Area End

  render() {
return (
      // Customizable Area Start
      <>
        <Box style={{ height: 'aoto' }}>
          <HeaderWeb />
          <Box style={webStyle.feeSchedulePageContainer}>
            <Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '0.5rem 0rem' }}>
                <Typography style={{ fontSize: '1.5rem', fontWeight: 'bold', textTransform: 'capitalize' }}>Fee Schedule</Typography>
                <NavLink to="/CvresumeCandidateManagement2">
                  <Button style={{ backgroundColor: 'red', color: 'white', textTransform: 'capitalize', width: '12rem', height: '3rem' }}>Schedule</Button>
                </NavLink>
              </Box>

              <Grid container>
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '1rem 0rem' }}>
                  <Typography style={webStyle.headings}>Candidate Details</Typography>
                  <Box style={{ height: '0px', border: '0.5px solid black', margin: '0rem 1rem', flexGrow: 1 }} />
                </Grid>
                <Grid container spacing={3} style={{ alignItems: 'end' }}>
                  <Grid item xs={6} >
                    <Typography style={webStyle.subHeadings}>Candidate name :</Typography>
                    <Input
                      placeholder="Candidate Name"
                      name='candidateName'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Typography style={webStyle.subHeadings}>Address :</Typography>
                    <InputField
                      select
                      name="city"
                      placeholder="Select State"
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                    >
                      {state.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  <Grid item xs={3}>
                    <InputField
                      select
                      name="city"
                      placeholder="Select City"
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                    >
                      {city.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '2rem 0rem' }}>
                  <Typography style={webStyle.headings}>Job Details</Typography>
                  <Box style={{ height: '0px', border: '0.5px solid black', margin: '0rem 1rem', flexGrow: 1 }} />
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={6}>
                    <Typography style={webStyle.subHeadings}>Company Name :</Typography>
                    <Input
                      placeholder="Company Name"
                      name='companyName'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography style={webStyle.subHeadings}>Hired Salary :</Typography>
                    <Input
                      placeholder="Salary"
                      name='salary'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography style={webStyle.subHeadings}>Position :</Typography>
                    <InputField
                      select
                      name="city"
                      placeholder="Select Position"
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                    >
                      {position.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography style={webStyle.subHeadings}>Start Date :</Typography>
                    <SelectInput
                      style={{ width: '100%' }}
                      placeholder="Select Date"
                      type="date"
                      defaultValue={this.state.formattedDate}
                       onChange={this.handleDateChange}
                       data-test-Id="date2"

                    >
                    </SelectInput>

                  </Grid>

                  <Grid item xs={3}>
                    <Typography style={webStyle.subHeadings}>Due Date:</Typography>
                    <SelectInput
                      style={{ width: '100%' }}
                      placeholder="Select Date"
                      type="date"
                      defaultValue={this.state.formattedDate}
                       onChange={this.handleDateChange}
                       data-test-Id="date"
                    >
                    </SelectInput>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography style={webStyle.subHeadings}>Hired From :</Typography>
                    <InputField
                      select
                      name="city"
                      placeholder="Select Medium"
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                    >
                      {medium.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </InputField>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography style={webStyle.subHeadings}>Fee :</Typography>
                    <Input
                      placeholder="Enter Fee Amount"
                      name='feeAmount'
                    />
                  </Grid>

                  <Grid item xs={12} style={{ height: '3rem' }}>
                    <Typography style={webStyle.subHeadings}>Notes :</Typography>
                    <InputNote
                      style={{ marginBottom: '2rem', height: '8rem', }}
                      placeholder="Notes..."
                      name='notes'
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

          </Box>
        </Box>
        <Footer />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  feeSchedulePageContainer: {
    width: '90vw',
    minHeight: '100vh',
    padding: '1rem 3rem',
    marginBottom: 200,
  },

  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  input: {
    border: 'none',
    backgroundColor: '#f5f5f5',

  },
  headings:{
    fontSize: '22px',
    fontWeight: '560',
  },
  subHeadings: {
    fontSize: '1.2rem',
    fontWeight: '560',
  },
  gridItemMargin: {
    margin: '0.25rem 0rem'
  },

};


const SelectInput: any = withStyles({
  root: {
    width: '99%',
    "& .MuiInputBase-input": {
      width: "100%",

      fontWeight: "Regular",
      height: "20px",
      padding: "20px",
      color: "rgba(132, 132, 132, 1)",
      borderRadius: "4px",
      marginTop: "5px",
      fontFamily: 'Roboto',
      background: "#F2F2F2",

    },
    "& .MuiSvgIcon-root": {
      paddingTop: "12px",
      color: "black",

    }
  }
})(InputBase);

const Input: any = withStyles({
  root: {
    width: '99%',
    "& .MuiInputBase-input": {


      background: "#F2F2F2",
      width: "100%",
      marginTop: "5px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      height: "45px",
      borderRadius: "4px",
      paddingLeft: "35px",
    }
  }
})(InputBase);

const InputField: any = withStyles({
  root: {
    width: '99%',
    "& .MuiFilledInput-root": {

      fontWeight: "Regular",
      marginTop: "4px",
      fontFamily: 'Roboto',
      background: "#F2F2F2",
      color: "rgba(132, 132, 132, 1)",
      borderRadius: "4px",
      "& .MuiFilledInput-input": {
        paddingTop: "20px",
        paddingBottom: "20px",
        textAlign: "center",
        border: "none",

      },
      "& .MuiSelect-nativeInput": {

        padding: "20px",
        fontFamily: 'Roboto',
        fontWeight: "Regular",
        opacity: 1,
        background: "#F2F2F2",
        border: "none",
        fontSize: "16px",
        color: 'rgba(132, 132, 132, 1)',
      },
      "& .MuiSelect-iconFilled": {
        color: "black",
      },
      "& .MuiSelect-select.MuiSelect-select ": {
        background: "#F2F2F2",
      },
      "& fieldset": {
        fontWeight: 400,
        borderRadius: "4px",

      },
    },
    "& ::placeholder": {
      opacity: 1,
    }
  }

})(TextField);

const InputNote: any = withStyles({
  root: {
    width: '99%',
    "& .MuiInputBase-input": {
      margin: "5px 0px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      background: "#F2F2F2",

      height: "90px",
      width: "99%",
      borderRadius: "4px",
      paddingLeft: "35px",
    }
  }
})(InputBase);



// Customizable Area End
