import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from "react";
// Customizable Area Start
export const configJSON = require("../config.js");
import { ApiCall } from '../../../../components/src/ApiCall.web';
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    apiToken: any,
    AdminConsoleData: any,
    open: boolean,
    alartBox: boolean,
    deleteId: number,
    deleteArray: Array<number>,
    alartMessage: string
    // Customizable Area End
}
interface SS {
    id: any;
    isUserLoggedIn: boolean;
}

export default class AdminConsoleToggleController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    AdminConsoleGetAPICallID: any;
    AdminConsoleDeleteAPICallID: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            AdminConsoleData: [],
            apiToken: "",
            open: false,
            alartBox: false,
            deleteId: NaN,
            deleteArray: [],
            alartMessage: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
    }
    async componentDidMount() {
        const token = (await localStorage.getItem("token")) || "";
        this.setState({ apiToken: token })
        this.handleAdminConsoleGetApi()
    }
    inputRef = React.createRef();


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    isValidResponse = (responseJson: any) => {
        return responseJson;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: any) => {
        return responseJson || responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.AdminConsoleGetAPICallID) {
            this.getAdminConsolegSuccess(responseJson);
        }
        if (apiRequestCallId === this.AdminConsoleDeleteAPICallID) {
            this.deleteAdminConsolegSuccess(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.AdminConsoleGetAPICallID) {
            this.getAdminConsoleFailuerCallBack(responseJson);
        }
        if (apiRequestCallId === this.AdminConsoleDeleteAPICallID) {
            this.deleteAdminConsoleFailuerCallBack(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start
    handleAdminConsoleGetApi = async () => {
        this.AdminConsoleGetAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.AdminConsoleGetApiEndPoint,
        });
    }
    // Customizable Area End

    // Customizable Area Start
    getAdminConsolegSuccess = (res: any) => {
        this.setState({ AdminConsoleData: res })
    }

    getAdminConsoleFailuerCallBack = (data: any) => {
    }
    // Customizable Area End

    handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ alartBox: false });
    };


    // Customizable Area Start
    handleAdminConsoleDeleteApi = async () => {
        const candidate_ids = {
            "candidate_ids": this.state.deleteArray
        }
        this.AdminConsoleDeleteAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.exampleDeleteAPiMethod,
            endPoint: configJSON.AdminConsoleDeleteApiEndPoint,
            body: candidate_ids
        });
    }
    // Customizable Area End

    // Customizable Area Start
    deleteAdminConsolegSuccess = (res: any) => {
        this.setState({ alartMessage: res?.message }, () => {
            this.setState({ alartBox: true }, () => this.handleAdminConsoleGetApi())
        })
    }
    // Customizable Area End

    // Customizable Area Start
    deleteAdminConsoleFailuerCallBack = (data: any) => {
        this.setState({ alartMessage: data?.message }, () => {
            this.setState({ alartBox: false })
        });
    }
    // Customizable Area End

    handleDeleteArray = (e: any) => {
        this.setState({ deleteArray: e })
    }

    handleOpen = () => this.setState({ open: !this.state.open })
}
