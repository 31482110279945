Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.mailListGetUrl = "bx_block_mailing_list/users";
exports.mailListApiMethodType = "GET";
exports.mailListApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MailingListIntegration";
exports.labelBodyText = "MailingListIntegration Body";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End