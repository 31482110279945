export const bagIcon = require("../assets/candidateSearch/image_bag.png");
export const capIcon = require("../assets/candidateSearch/image_cap.png");
export const closeIcon = require("../assets/candidateSearch/image_close.png");
export const searchWhite = require('../assets/search_white.png');
export const infoIcon1 = require("../assets/candidateSearch/image_info.png");
export const locationIcon = require("../assets/candidateSearch/image_location.png");
export const redTick = require("../assets/candidateSearch/image_tick.png");
export const downArrow = require("../assets/candidateSearch/image_arrow.png");
export const profileImg = require("../assets/candidateSearch/image_profile.png");
export const dateIcon = require("../assets/candidateSearch/image_calender.png");
export const download = require("../assets/download.png");
export const view_Growth = require("../assets/view_Growth.png");
export const view_Positive = require("../assets/view_Positive.png");
export const outlook = require("../assets/candidateSearch/outlook.png");
export const searchIcon = require('../assets/image_search.png');
