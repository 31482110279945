import React from "react";
// Customizable Area Start
import {
  Typography,
  Grid,
  Avatar,
  TextField,
  InputLabel,
  Button,
  InputAdornment,
  Box,
  IconButton, CircularProgress, Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import HeaderWeb from "../../../components/src/Header.web";
import { Formik } from 'formik';
import * as Yup from 'yup'
const theme = createTheme({
  palette: {
    primary: {
      main: "#ebe534",
      contrastText: "#fff",
    },
  },
});
interface FormProps {
  errors: any;
  values: any;
  touched: any;
  handleSubmit: () => void;
  handleChange: () => void;
  handleBlur: () => void;
}
export const configJSON = require("./config");
// Customizable Area End

import UserProfileBasicControllerWeb, {
  Props,
} from "./UserProfileBasicController.web";

export default class UserProfileBasicBlockWeb extends UserProfileBasicControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    const passwordValidationSchema = Yup.object().shape({
      current_password: Yup.string()
        .required("Last password is required"),
      new_password: Yup.string()
        .required("New password is required")
        .min(8, "Must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number")
        .when('current_password', (current_password: any, schema: any) => {
          return schema.test({
            test: (new_password: any) => new_password !== current_password,
            message: 'Last password and new password can not be same, try using different password'
          });
        }),
      first_name: Yup.string()
        .required('First Name is required')
        .matches(
          /[a-zA-Z]/,
          'Enter at least one character'
        ),
      phone_number: Yup.string()
        .required('Phone number is required')
        .matches(configJSON.phoneNumberValidation, 'Please enter a valid number')

    });
    const nameValidationSchema = Yup.object().shape({
      first_name: Yup.string()
        .required('First Name is required')
        .matches(
          /[a-zA-Z]/,
          'Enter at least one character'
        ),
      phone_number: Yup.string()
        .required('phone number is required')
        .matches(configJSON.phoneNumberValidation, 'Please enter a valid number')

    })

    const { first_name, photo }: any = this.state.userDetails.attributes || {}
    return (
      //Required for all blocks
      <MainComponent
        state={this.state}
        passwordValidationSchema={passwordValidationSchema}
        nameValidationSchema={nameValidationSchema}
        handleProfilePhotoUpload={this.handleProfilePhotoUpload}
        photo={photo}
        first_name={first_name}
        handleClickHidePassword={this.handleClickHidePassword}
        handleClickShowPassword={this.handleClickShowPassword}
        handleChangePassword={this.handleChangePassword}
        handleSubmit={this.handleSubmit}
        finalErrorMessage={this.finalErrorMessage}
      />
    );
    // Customizable Area End
  }



  // Customizable Area Start
  // Customizable Area End

}
// Customizable Area Start
/* istanbul ignore next */
function MainComponent(props: any) {
  return (
    <>
      <HeaderWeb />
      <ThemeProvider theme={theme}>
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={11}>
            <Typography style={webStyle.typography}>
              Profile Details
            </Typography>

            <Box sx={{ width: "100%" }}>
              {props.state.pageLoading ? <div style={webStyle.root}>
                <div style={webStyle.circularContainer}>
                  <CircularProgress />
                </div>
              </div> :
                <Formik
                  initialValues={props.state.userDetails.attributes}
                  validationSchema={props.state.showPasswordInput ? props.passwordValidationSchema : props.nameValidationSchema}
                  onSubmit={(values: any) => {
                    props.handleSubmit(values);
                  }}
                  data-test-id="loginFormik"
                >
                  {({ errors, values, touched, handleSubmit, handleChange, handleBlur }: FormProps) => (
                    <form style={webStyle.form} onSubmit={handleSubmit}>
                      <Grid container style={{ width: "100%", justifyContent: "center" }} >
                        <Grid item xs={12} md={3}>
                          <Grid container style={{ justifyContent: "center" }}>
                            <input
                              accept="image/*"
                              style={webStyle.profileUpload}
                              id="contained-button-file"
                              type="file"
                              onChange={props.handleProfilePhotoUpload}
                            />
                            <label htmlFor="contained-button-file">
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                <Avatar style={webStyle.avatar} src={props.state.selectedProfileFile ? props.state.selectedProfileFile : props.photo} alt='photo' />
                              </IconButton>
                            </label>
                          </Grid>
                          <Grid container style={webStyle.username}>{`${props.first_name}`}</Grid>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <Grid container style={webStyle.width100}>
                            <Grid item xs={12} sm={6} style={webStyle.input}>
                              <InputLabel style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: 'Open Sans', fontSize: "18px", marginTop: "10px" }}>
                                Your Name
                              </InputLabel>
                              <InputField
                                id="name"
                                value={values?.first_name}
                                onChange={handleChange}
                                name="first_name"
                                variant="outlined"
                              />
                              {props.finalErrorMessage(errors.first_name, touched?.first_name)}
                            </Grid>
                            <Grid item xs={12} sm={6} style={webStyle.input}>
                              <InputLabel
                                style={{
                                  color: 'rgba(0, 0, 0, 1)',
                                  fontFamily: 'Open Sans',
                                  fontSize: "18px",
                                  marginTop: "10px"
                                }}
                              >
                                Email Address
                              </InputLabel>
                              <InputField
                                id="email"
                                name="email"
                                value={values?.email}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                              />

                            </Grid>
                            <Grid item xs={12} sm={6} style={webStyle.input}>
                              <InputLabel style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: 'Open Sans', fontSize: "18px", marginTop: "10px" }}>
                                Phone Number
                              </InputLabel>
                              <InputField
                                name="phone_number"
                                onChange={handleChange}
                                value={values?.phone_number}
                                variant="outlined" />
                              {props.finalErrorMessage(errors.phone_number, touched.phone_number)}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <Button variant="contained"
                                style={{
                                  background: "black",
                                  color: "white",
                                  height: "44px",
                                  fontStyle: "Roman",
                                  margin: "39px 12px",
                                  fontFamily: "Open Sans",
                                  textTransform: "none",
                                }}
                                onClick={props.handleChangePassword}
                              >
                                Change Password
                              </Button>
                            </Grid>
                            {props.state.showPasswordInput &&
                              <Grid container>
                                <Grid item xs={12} sm={6} style={webStyle.input}>
                                  <InputLabel style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: 'Open Sans', fontSize: "18px", marginTop: "10px" }}>Last Password</InputLabel>
                                  <InputField
                                    data-test-id="txtInputPassword"
                                    type={props.state.enablePasswordField ? "password" : "text"}
                                    id="password"
                                    name="current_password"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickShowPassword}
                                            edge="end"
                                            style={{ color: "black" }}
                                          >
                                            {props.state.enablePasswordField ? <VisibilityOff /> : <Visibility />}

                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                  />

                                  {props.finalErrorMessage(errors.current_password, touched.current_password)}
                                </Grid>
                                <Grid item xs={12} sm={6} style={webStyle.input}>
                                  <InputLabel style={{ color: 'rgba(0, 0, 0, 1)', fontFamily: 'Open Sans', fontSize: "18px", marginTop: "10px" }}>New Password</InputLabel>
                                  <InputField
                                    data-test-id="txtInputPassword"
                                    type={props.state.enableNewPasswordField ? "password" : "text"}
                                    id="password"
                                    name="new_password"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={props.handleClickHidePassword}
                                            edge="end"
                                            style={{ color: "black" }}
                                          >
                                            {props.state.enableNewPasswordField ? <VisibilityOff /> : <Visibility />}

                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}

                                  />

                                  {props.finalErrorMessage(errors.new_password, touched.new_password)}
                                </Grid>

                              </Grid>
                            }
                            <Grid item xs={12} sm={12} style={webStyle.input}>
                              <Button variant="contained"
                                style={{
                                  background: "black",
                                  color: "white",
                                  marginTop: "62px",
                                  height: "59px",
                                  width: "100%",
                                  textTransform: "none",
                                  fontFamily: 'Open Sans',
                                }}
                                type='submit'
                              >
                                Save Changes
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>}
            </Box>
          </Grid>
          <Snackbar autoHideDuration={2000} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
            open={props.state.openNotification} >
            <MuiAlert elevation={6} variant="filled" severity={props.state.isError ? 'error' : 'success'} >
              {props.state.messageText}
            </MuiAlert>
          </Snackbar>
          <Snackbar autoHideDuration={2000} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
            open={props.state.errorOpenNotification} >
            <MuiAlert elevation={6} variant="filled" severity={props.state.isError ? 'error' : 'success'} >
              {props.state.messageText}
            </MuiAlert>
          </Snackbar>
        </Grid>
      </ThemeProvider>
    </>
  )
}
const webStyle: any = {
  typography: {
    fontSize: "32px",
    paddingTop: "32px",
    fontFamily: 'Poppins'
  },
  profileUpload: {
    display: "none",
  },
  root: {
    position: "fixed",
    inset:0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  circularContainer: {
    display:"inline-block",
  },
  width100: {
    width: "100%"
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0px"
  },
  avatar: {
    width: "166px",
    height: "166px",
  },
  username: {
    marginTop: "14px",
    justifyContent: "center",
    fontSize: "22px",
    fontWeight: "bold",
    fontFamily: 'Open Sans',
  },
  form: {
    border: "1px solid #979797",
    padding: "60px 0px",
    marginTop: "30px",
    width: "100%",
  },
  input: {
    padding: "0px 12px",
  }

};
const InputField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: "18px",
      marginTop: "11px",
      fontFamily: 'Open Sans',
      "& fieldset": {
        fontSize: "18px",
        borderRadius: "7px",
        borderWidth: 1,
        fontWeight: 400,
        border: "1px solid #979797",
        fontFamily: 'Open Sans'
      },
      "& .MuiInputBase-input ": {
        color: "black",
        fontFamily: 'Open Sans'
      },

    },
    "& ::placeholder": {
      opacity: 1,
      color: 'rgba(0, 0, 0, 1)',
      fontFamily: 'Open Sans'
    }
  }

})(TextField);

// Customizable Area End
