import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ApiCall } from "../ApiCall.web";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    jobList: any[];
    dragItem: number;
    dragOverItem: number;
    jobListSequence: any[];
    topicTitle: string;
    topicDescription: string;
    isFromExpanded: boolean;
    deleteId: number;
    editId: number;
    errorMessage: string;
    isEditTopic: boolean;
    reOrderedList: any[];
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class PrioritiseController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getJobsApiCallId: any;
    createTopicApiCallId: any;
    deleteTopicApiCallId: any;
    updateTopicApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        // Customizable Area Start
        this.state = {
            jobList: [],
            dragItem: -1,
            dragOverItem: -1,
            jobListSequence: [],
            topicTitle: "",
            topicDescription: "",
            isFromExpanded: false,
            deleteId: 0,
            editId: 0,
            errorMessage: "",
            isEditTopic: false,
            reOrderedList: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        await this.getJobList();
    }

    async receive(_from: string, message: Message): Promise<void> {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (this.getJobsApiCallId === apiRequestCallId) {
                if (responseJson.length > 0) {
                    this.setState({ jobList: responseJson }, () => {
                        const data = this.state.jobList.map(item => item.id);
                        this.setState({ reOrderedList: data });
                    });
                }
            }

            if (this.createTopicApiCallId === apiRequestCallId) {
                this.createTopicApiProcess(responseJson);
            }

            if (this.deleteTopicApiCallId === apiRequestCallId) {
                this.deleteTopicApiProcess(responseJson);
            }

            if(this.updateTopicApiCallId === apiRequestCallId) {
                this.updateTopicApiProcess(responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    createTopicApiProcess = (responseJson:any) => {
        if (responseJson && !responseJson.errors) {
            if(this.state.reOrderedList.length > 0) {
                const newArr = [responseJson.id, ...this.state.reOrderedList];
                this.setState({ 
                    reOrderedList: newArr, 
                    isFromExpanded: false, 
                    topicTitle: "",
                    topicDescription: "", 
                }, () => {
                    this.getJobList();
                });
            }else {
                this.setState({ isFromExpanded: false, topicTitle: "", topicDescription: "" }, () => {
                    this.getJobList();
                });
            }
        }
    }

    deleteTopicApiProcess = (responseJson:any) => {
        if (responseJson && !responseJson.errors) {
            if(this.state.reOrderedList.length > 0) {
                const newArr = this.state.reOrderedList.filter(item => item !== this.state.deleteId);
                this.setState({ reOrderedList: newArr, deleteId: 0 }, () => {
                    this.getJobList();
                });
            }else {
                this.setState({deleteId : 0}, () => {
                    this.getJobList();
                });
            }
        }
    }

    updateTopicApiProcess = (responseJson:any) => {
        if(responseJson && !responseJson.errors) {
            this.setState({ 
                isFromExpanded: false, 
                isEditTopic: false, 
                editId: 0,
                topicTitle: "",
                topicDescription: "", 
            }, () => { this.getJobList() });
        }
    }

    dragEnter = (position: number): void => {
        this.setState({ dragOverItem: position });
    };

    dragStart = (position: number): void => {
        this.setState({ dragItem: position });
    };

    drop = (): void => {
        const { jobList, dragItem, dragOverItem } = this.state;

        const copyListItems = [...jobList];
        const dragItemContent = copyListItems[dragItem];
        copyListItems.splice(dragItem, 1);
        copyListItems.splice(dragOverItem, 0, dragItemContent);
        this.setState({ dragItem: -1, dragOverItem: -1 });
        this.setState({ jobList: copyListItems },() => {
            const idsList = this.state.jobList?.map(item => item.id);
            this.setState({ reOrderedList: idsList }, () => {this.getJobList()});
        }); 
    };

    handleFormSubmit = async (e: any) => {
        e.preventDefault();
        const { isFromExpanded, topicTitle, topicDescription, isEditTopic, editId } = this.state;

        if (!isFromExpanded) {
            this.setState({ isFromExpanded: true });
        } else {
            if (topicTitle.trim() || topicDescription.trim()) {
                isEditTopic ? this.updateTopic(editId) : this.createTopic();
            }else {
                this.setState({ errorMessage: "Please enter title or description" });
            }
        }
    }

    handleInputChange = (e: any) => {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value, errorMessage: "" });
    }

    handleTopicUpdate = (id:number) => {
        const updatableTopic = this.state.jobList.find(item => item.id === id);
        if(updatableTopic) {
            this.setState({
                topicTitle: updatableTopic.title,
                topicDescription: updatableTopic.description,
                isFromExpanded: true,
                isEditTopic: true,
                editId: id
            }, () => {this.getJobList()});
        }
    }

    getJobList = async () => {
        const { reOrderedList } = this.state;
        const userToken = localStorage.getItem("token");

        const params = new URLSearchParams();
        if(reOrderedList.length > 0) {
            reOrderedList.forEach(item => params.append("ids[]", item)); 
        }

        const urlData = reOrderedList.length > 0 ? `?${params}` : "";

        this.getJobsApiCallId = await ApiCall({
            contentType: "application/json",
            token: userToken,
            method: "GET",
            endPoint: `bx_block_posts/index_data/${urlData}`
        });
    }

    createTopic = async () => {
        const userToken = localStorage.getItem("token");

        const httpBody = {
            "job": {
                "description": this.state.topicDescription,
                "title": this.state.topicTitle
            }
        }

        this.createTopicApiCallId = await ApiCall({
            contentType: "application/json",
            token: userToken,
            method: "POST",
            endPoint: "bx_block_posts/jobs",
            body: httpBody
        });
    }

    deleteTopic = async (id: number) => {
        const userToken = localStorage.getItem("token");
        this.setState({ deleteId: id });

        this.deleteTopicApiCallId = await ApiCall({
            contentType: "application/json",
            token: userToken,
            method: "DELETE",
            endPoint: "bx_block_posts/jobs" + `/${id}`,
        });
    }

    updateTopic = async (id: number) => {
        const userToken = localStorage.getItem("token");

        const httpBody = {
            "job": {
                "description": this.state.topicDescription,
                "title": this.state.topicTitle
            }
        }

        this.updateTopicApiCallId = await ApiCall({
            contentType: "application/json",
            token: userToken,
            method: "PUT",
            endPoint: "bx_block_posts/jobs" + `/${id}`,
            body: httpBody,
        });        
    }
    // Customizable Area End
}
