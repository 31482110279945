import React from "react";
import HeaderWeb from "../../../../components/src/Header.web";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { DataGrid } from "@material-ui/data-grid";
import { Delete, threeDots } from '../assets';
import AdminConsoleToggleController from './AdminConsoleToggleController.web';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import moment from "moment";


const columns = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.3,
    editable: false,
    sortable: false,
  },
  {
    field: "candidate_name",
    headerName: "Candidate Name",
    flex: 1,
    editable: false,
    sortable: false,
  },
  {
    field: "position",
    headerName: "Job Type",
    flex: 1,
    editable: false,
    sortable: false,
  },
  {
    field: "company_name",
    headerName: "Company",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "city",
    headerName: "Location",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "submission_date",
    headerName: "Submitted Date",
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: (asd: any) => {
      return (
        <>
          {moment(asd?.formattedValue).format('MMM, DD YYYY')}
        </>
      )
    }
  },
  {
    field: "interview_date",
    headerName: "Interview Date",
    flex: 1,
    editable: false,
    sortable: false,
    renderCell: (asd: any) => {
      return (
        <>
          {moment(asd?.formattedValue).format('MMM, DD YYYY')}
        </>
      )
    }
  },
  {
    field: "clearence_level",
    headerName: "Clearance",
    flex: 1,
    editable: false,
    sortable: false
  },
  {
    field: "",
    headerName: "",
    flex: 0.3,
    editable: false,
    sortable: false,
    renderCell: (asd: any) => {
      return (
        <img src={threeDots} alt="readmore" height={20} />
      )
    }
  }
];



export default class AdminConsoleToggle extends AdminConsoleToggleController {
  render() {
    return (
      <div>
        {this.state.alartBox && (
          <Snackbar open={this.state.alartBox} autoHideDuration={6000} onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.state.alartMessage === "Candidates were successfully destroyed" ? "success" : "warning"}>
              {this.state.alartMessage}
            </Alert>
          </Snackbar>
        )}

        <HeaderWeb />
        <div className="admin_header_wrapper">
          <div className="admin_header_text_div">
            ADMIN CONSOLE
          </div>
          <div className="delete_icon_div" onClick={this.handleAdminConsoleDeleteApi} data-testid="deleteButtonId">
            <img src={Delete} alt="delete" />
            Delete Selected
          </div>
        </div>
        <div className="table_header_div" >
          <div onClick={this.handleOpen} className="TableCell-Table-Icon">
            {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
          <div className="table_header_name">Candidates</div>
        </div>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit className="TableCell-Table-Div">
          <div className="table_div">
            <span className="all_div_wrapper_container">All</span>
            <DataGrid
              rows={this.state.AdminConsoleData.active_candidates}
              pageSize={5}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(item: any) => this.handleDeleteArray(item)}
              className="admin-console-grid"
            />
          </div>
        </Collapse>
      </div>
    )
  }
}