import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import StorageProviderWeb from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any,
  id: string;
  // Customizable Area Start
  projectTitle?: string;
  // Customizable Area End
}
export interface CandidateInternalAttributes {
  id: string,
  candidate_name: string,
  address: string,
  company_name: string,
  position: string,
  state: string,
  city: string,
  recruiter_name: string,
  created_at: string,
  updated_at: string,
  medium: string
  recruiter: string,
  contacted: string,
  submitted: string,
  intrested: string,
  designation: string,
  submission_date: string,
  interview_date: string,
  category: string,
  clearence_level: string,
}
export interface ContactedCandidatesAttributes {
  id: string,
  candidate_name: string,
  address: string,
  company_name: string,
  position: string,
  recruiter_name: string,
  created_at: string,
  updated_at: string,
  medium: string,
  recruiter: string,
  contacted: string,
  submitted: string,
  intrested: string,
  designation: string,
  submission_date: string,
  interview_date: string,
  category: string,
  clearence_level: string,
  city: string,
  salary: string,
  start_date: string,
  due_date: string,
  notes: string,
  fee: string,
  state: string,
  education: string,
  experience: string,
  active: string,
  job_platform: string,
  job_type: string,
  amount: string,
  time: string
}

interface checkboxContainer {
  [k: string]: boolean;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  dataTab: string[];
  newValue: string;
  candidateName: string;
  state: string;
  city: string;
  companyName: string;
  hiredSalary: string;
  position: string;
  startDate: Date;
  dueDate: Date;
  hiredFrom: string;
  feeAmount: string;
  notes: string;
  token: string;
  activeCandidateList: Array<CandidateInternalAttributes>
  contactedCandidateList: Array<ContactedCandidatesAttributes>
  linkDisabled: boolean,
  checkboxValues: checkboxContainer,
  search: string
  // Customizable Area End
}


interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActiveCandidateDetails!: string;
  getAllContactedCandidatesDetails!: string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      newValue: "1",
      dataTab: [""],
      candidateName: "",
      state: "",
      city: "",
      companyName: "",
      hiredSalary: "",
      position: "",
      startDate: new Date(),
      dueDate: new Date(),
      hiredFrom: "",
      feeAmount: "",
      notes: "",
      token: "",
      activeCandidateList: [],
      contactedCandidateList: [],
      linkDisabled: false,
      checkboxValues: {
        all: true,
        contacted: false,
        submitted: false,
        interested: false,
      },
      search: ''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getAllContactedCandidates('');
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    if (localStorage.getItem('token')) {
      let token = await this.getStorageProvider()
      this.setUpdateToken(token);
    }
  }

  setUpdateToken = (token: string) => {
    this.setState({ token: token }, () => {
      this.getAllActiveCandidateDetails()
    });
  }

  getStorageProvider = async () => {
    return await StorageProviderWeb.get("token");
  }
  // Web Event Handling


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId != null) {
      /* istanbul ignore next */
      if (apiRequestCallId === this.getActiveCandidateDetails) {
        //On Success response
        /* istanbul ignore next */
        if (responseJson) {
          this.setState({
            activeCandidateList: responseJson.active_candidates
          })
        }
      }
      if (apiRequestCallId === this.getAllContactedCandidatesDetails) {
        //On Success response
        /* istanbul ignore next */
        if (responseJson) {
          this.setState({
            contactedCandidateList: responseJson
          })
        }
      }
    }

  }
  // web events
  // Customizable Area Start

  handleClick = () => {
    this.props.navigation.navigate("FeeSchedules", {
      screen: "FeeSchedules"
    });
  }

  handleChangeTab = (event: any, newValue: string) => {
    this.setState({
      newValue: newValue,
      checkboxValues: {
        all: true,
        contacted: false,
        submitted: false,
        interested: false,
      },
    });
  };

  //Search active candidate
  /* istanbul ignore next */
  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let timer: ReturnType<typeof setTimeout>;
    this.setState({
      search: event.target.value
    }, () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.getAllActiveCandidateDetails()
      }, 300)
    })
  }

  filterContactedCandidates = (checkboxId: string) => {
    /* istanbul ignore next */
    if (checkboxId === "all") {
      this.setState(() => ({
        checkboxValues: {
          [checkboxId]: true // Toggle the checkbox value
        }
      }), () => {
        this.getAllContactedCandidates(checkboxId ? checkboxId : '')
      });
    } else {
      this.setState((prevState) => ({
        checkboxValues: {
          [checkboxId]: !prevState.checkboxValues[checkboxId] // Toggle the checkbox value
        }
      }), () => {
        this.getAllContactedCandidates(checkboxId ? checkboxId : '')
      });
    }
  };
  getAllActiveCandidateDetails = async () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getActiveCandidateDetails = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      /* istanbul ignore next */
      this.state.search ? `account_block/candidates/candidates?search=${this.state.search}` : `account_block/candidates/candidates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllContactedCandidates = async (checkboxId: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllContactedCandidatesDetails = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      /* istanbul ignore next */
      (checkboxId !== "all") && (this.state.checkboxValues[checkboxId]) ? `account_block/candidates/get_contact_candidates?status=${checkboxId}` : `account_block/candidates/get_contact_candidates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

// Customizable Area End